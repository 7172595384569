import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CountryService {

  public countries = {
    Azerbaijan: { countryCode: 'az', dialCode: '994' },
    Bahamas: { countryCode: 'bs', dialCode: '1242' },
    Bahrain: { countryCode: 'bh', dialCode: '973' },
    Bangladesh: { countryCode: 'bd', dialCode: '880' },
    Barbados: { countryCode: 'bb', dialCode: '1246' },
    Belarus: { countryCode: 'by', dialCode: '375' },
    Belgium: { countryCode: 'be', dialCode: '32' },
    Belize: { countryCode: 'bz', dialCode: '501' },
    Benin: { countryCode: 'bj', dialCode: '229' },
    Bermuda: { countryCode: 'bm', dialCode: '1441' },
    Bhutan: { countryCode: 'bt', dialCode: '975' },
    Bolivia: { countryCode: 'bo', dialCode: '591' },
    'Bosnia And Herzegowina': { countryCode: 'ba', dialCode: '387' },
    Botswana: { countryCode: 'bw', dialCode: '267' },
    Brazil: { countryCode: 'br', dialCode: '55' },
    'British Indian Ocean Territory': { countryCode: 'io', dialCode: '246' },
    'Virgin Islands (british)': { countryCode: 'vg', dialCode: '1284' },
    'Brunei Darussalam': { countryCode: 'bn', dialCode: '673' },
    Bulgaria: { countryCode: 'bg', dialCode: '359' },
    'Burkina Faso': { countryCode: 'bf', dialCode: '226' },
    Burundi: { countryCode: 'bi', dialCode: '257' },
    Cambodia: { countryCode: 'kh', dialCode: '855' },
    Cameroon: { countryCode: 'cm', dialCode: '237' },
    Canada: { countryCode: 'ca', dialCode: '1' },
    'Cape Verde': { countryCode: 'cv', dialCode: '238' },
    'Caribbean Netherlands': { countryCode: 'bq', dialCode: '599' },
    'Cayman Islands': { countryCode: 'ky', dialCode: '1345' },
    'Central African Republic': { countryCode: 'cf', dialCode: '236' },
    Chad: { countryCode: 'td', dialCode: '235' },
    Chile: { countryCode: 'cl', dialCode: '56' },
    China: { countryCode: 'cn', dialCode: '86' },
    'Christmas Island': { countryCode: 'cx', dialCode: '61' },
    Colombia: { countryCode: 'co', dialCode: '57' },
    Comoros: { countryCode: 'km', dialCode: '269' },
    Congo: { countryCode: 'cd', dialCode: '243' },
    'Congo Republic': { countryCode: 'cg', dialCode: '242' },
    'Cook Islands': { countryCode: 'ck', dialCode: '682' },
    'Costa Rica': { countryCode: 'cr', dialCode: '506' },
    'Cote Divoire': { countryCode: 'ci', dialCode: '225' },
    'Croatia ': { countryCode: 'hr', dialCode: '385' },
    Cuba: { countryCode: 'cu', dialCode: '53' },
    Curacao: { countryCode: 'cw', dialCode: '599' },
    Cyprus: { countryCode: 'cy', dialCode: '357' },
    'Czech Republic': { countryCode: 'cz', dialCode: '420' },
    Denmark: { countryCode: 'dk', dialCode: '45' },
    Djibouti: { countryCode: 'dj', dialCode: '253' },
    Dominica: { countryCode: 'dm', dialCode: '1767' },
    'Dominican Republic': { countryCode: 'do', dialCode: '1' },
    Ecuador: { countryCode: 'ec', dialCode: '593' },
    Egypt: { countryCode: 'eg', dialCode: '20' },
    'El Salvador': { countryCode: 'sv', dialCode: '503' },
    'Equatorial Guinea': { countryCode: 'gq', dialCode: '240' },
    Eritrea: { countryCode: 'er', dialCode: '291' },
    Estonia: { countryCode: 'ee', dialCode: '372' },
    Ethiopia: { countryCode: 'et', dialCode: '251' },
    'Falkland Islands (malvinas)': { countryCode: 'fk', dialCode: '500' },
    'Faroe Islands': { countryCode: 'fo', dialCode: '298' },
    Fiji: { countryCode: 'fj', dialCode: '679' },
    Finland: { countryCode: 'fi', dialCode: '358' },
    France: { countryCode: 'fr', dialCode: '33' },
    'French Guiana': { countryCode: 'gf', dialCode: '594' },
    'French Polynesia': { countryCode: 'pf', dialCode: '689' },
    Gabon: { countryCode: 'ga', dialCode: '241' },
    Gambia: { countryCode: 'gm', dialCode: '220' },
    Georgia: { countryCode: 'ge', dialCode: '995' },
    Germany: { countryCode: 'de', dialCode: '49' },
    Ghana: { countryCode: 'gh', dialCode: '233' },
    Gibraltar: { countryCode: 'gi', dialCode: '350' },
    Greece: { countryCode: 'gr', dialCode: '30' },
    Greenland: { countryCode: 'gl', dialCode: '299' },
    Grenada: { countryCode: 'gd', dialCode: '1473' },
    Guadeloupe: { countryCode: 'gp', dialCode: '590' },
    Guam: { countryCode: 'gu', dialCode: '1671' },
    Guatemala: { countryCode: 'gt', dialCode: '502' },
    Guernsey: { countryCode: 'gg', dialCode: '44' },
    Guinea: { countryCode: 'gn', dialCode: '224' },
    'Guinea-bissau': { countryCode: 'gw', dialCode: '245' },
    Guyana: { countryCode: 'gy', dialCode: '592' },
    Haiti: { countryCode: 'ht', dialCode: '509' },
    Honduras: { countryCode: 'hn', dialCode: '504' },
    'Hong Kong': { countryCode: 'hk', dialCode: '852' },
    Hungary: { countryCode: 'hu', dialCode: '36' },
    Iceland: { countryCode: 'is', dialCode: '354' },
    India: { countryCode: 'in', dialCode: '91' },
    Indonesia: { countryCode: 'id', dialCode: '62' },
    'Iran ': { countryCode: 'ir', dialCode: '98' },
    Iraq: { countryCode: 'iq', dialCode: '964' },
    Ireland: { countryCode: 'ie', dialCode: '353' },
    'Isla De Man': { countryCode: 'im', dialCode: '44' },
    Israel: { countryCode: 'il', dialCode: '972' },
    Italy: { countryCode: 'it', dialCode: '39' },
    Jamaica: { countryCode: 'jm', dialCode: '1876' },
    Japan: { countryCode: 'jp', dialCode: '81' },
    Jersey: { countryCode: 'je', dialCode: '44' },
    Jordan: { countryCode: 'jo', dialCode: '962' },
    Kazakhstan: { countryCode: 'kz', dialCode: '7' },
    Kenya: { countryCode: 'ke', dialCode: '254' },
    Kiribati: { countryCode: 'ki', dialCode: '686' },
    Kosovo: { countryCode: 'xk', dialCode: '383' },
    Kuwait: { countryCode: 'kw', dialCode: '965' },
    Kyrgyzstan: { countryCode: 'kg', dialCode: '996' },
    Lao: { countryCode: 'la', dialCode: '856' },
    Latvia: { countryCode: 'lv', dialCode: '371' },
    Lebanon: { countryCode: 'lb', dialCode: '961' },
    Lesotho: { countryCode: 'ls', dialCode: '266' },
    Liberia: { countryCode: 'lr', dialCode: '231' },
    'Libyan Arab Jamahiriya': { countryCode: 'ly', dialCode: '218' },
    Liechtenstein: { countryCode: 'li', dialCode: '423' },
    Lithuania: { countryCode: 'lt', dialCode: '370' },
    Luxembourg: { countryCode: 'lu', dialCode: '352' },
    Macau: { countryCode: 'mo', dialCode: '853' },
    Macedonia: { countryCode: 'mk', dialCode: '389' },
    Madagascar: { countryCode: 'mg', dialCode: '261' },
    Malawi: { countryCode: 'mw', dialCode: '265' },
    Malaysia: { countryCode: 'my', dialCode: '60' },
    Maldives: { countryCode: 'mv', dialCode: '960' },
    Mali: { countryCode: 'ml', dialCode: '223' },
    Malta: { countryCode: 'mt', dialCode: '356' },
    'Marshall Islands': { countryCode: 'mh', dialCode: '692' },
    Martinique: { countryCode: 'mq', dialCode: '596' },
    Mauritania: { countryCode: 'mr', dialCode: '222' },
    Mauritius: { countryCode: 'mu', dialCode: '230' },
    Mayotte: { countryCode: 'yt', dialCode: '262' },
    Mexico: { countryCode: 'mx', dialCode: '52' },
    Micronesia: { countryCode: 'fm', dialCode: '691' },
    Moldova: { countryCode: 'md', dialCode: '373' },
    Monaco: { countryCode: 'mc', dialCode: '377' },
    Mongolia: { countryCode: 'mn', dialCode: '976' },
    Montenegro: { countryCode: 'me', dialCode: '382' },
    Montserrat: { countryCode: 'ms', dialCode: '1664' },
    Morocco: { countryCode: 'ma', dialCode: '212' },
    Mozambique: { countryCode: 'mz', dialCode: '258' },
    Myanmar: { countryCode: 'mm', dialCode: '95' },
    Namibia: { countryCode: 'na', dialCode: '264' },
    Nauru: { countryCode: 'nr', dialCode: '674' },
    Nepal: { countryCode: 'np', dialCode: '977' },
    Netherlands: { countryCode: 'nl', dialCode: '31' },
    'New Caledonia': { countryCode: 'nc', dialCode: '687' },
    'New Zealand': { countryCode: 'nz', dialCode: '64' },
    Nicaragua: { countryCode: 'ni', dialCode: '505' },
    Niger: { countryCode: 'ne', dialCode: '227' },
    Nigeria: { countryCode: 'ng', dialCode: '234' },
    Niue: { countryCode: 'nu', dialCode: '683' },
    'Norfolk Island': { countryCode: 'nf', dialCode: '672' },
    'Korea North ': { countryCode: 'kp', dialCode: '850' },
    'Northern Mariana Islands': { countryCode: 'mp', dialCode: '1670' },
    Norway: { countryCode: 'no', dialCode: '47' },
    Oman: { countryCode: 'om', dialCode: '968' },
    Pakistan: { countryCode: 'pk', dialCode: '92' },
    Palau: { countryCode: 'pw', dialCode: '680' },
    Palestina: { countryCode: 'ps', dialCode: '970' },
    Panama: { countryCode: 'pa', dialCode: '507' },
    'Papua New Guinea': { countryCode: 'pg', dialCode: '675' },
    Paraguay: { countryCode: 'py', dialCode: '595' },
    Peru: { countryCode: 'pe', dialCode: '51' },
    Philippines: { countryCode: 'ph', dialCode: '63' },
    Poland: { countryCode: 'pl', dialCode: '48' },
    Portugal: { countryCode: 'pt', dialCode: '351' },
    'Puerto Rico': { countryCode: 'pr', dialCode: '1' },
    Qatar: { countryCode: 'qa', dialCode: '974' },
    Reunion: { countryCode: 're', dialCode: '262' },
    Romania: { countryCode: 'ro', dialCode: '40' },
    'Russian Federation': { countryCode: 'ru', dialCode: '7' },
    Rwanda: { countryCode: 'rw', dialCode: '250' },
    'San Bartolomé': { countryCode: 'bl', dialCode: '590' },
    'Saint Helena': { countryCode: 'sh', dialCode: '290' },
    'Saint Kitts And Nevis': { countryCode: 'kn', dialCode: '1869' },
    'Saint Lucia': { countryCode: 'lc', dialCode: '1758' },
    'Saint Pierre And Miquelon': { countryCode: 'pm', dialCode: '508' },
    'Saint Vincent And The Grenadines': { countryCode: 'vc', dialCode: '1784' },
    Samoa: { countryCode: 'ws', dialCode: '685' },
    'San Marino': { countryCode: 'sm', dialCode: '378' },
    'Sao Tome And Principe': { countryCode: 'st', dialCode: '239' },
    'Saudi Arabia': { countryCode: 'sa', dialCode: '966' },
    Senegal: { countryCode: 'sn', dialCode: '221' },
    Serbia: { countryCode: 'rs', dialCode: '381' },
    Seychelles: { countryCode: 'sc', dialCode: '248' },
    'Sierra Leone': { countryCode: 'sl', dialCode: '232' },
    Singapore: { countryCode: 'sg', dialCode: '65' },
    'Sint Maarten': { countryCode: 'sx', dialCode: '1721' },
    Slovakia: { countryCode: 'sk', dialCode: '421' },
    Slovenia: { countryCode: 'si', dialCode: '386' },
    'Solomon Islands': { countryCode: 'sb', dialCode: '677' },
    Somalia: { countryCode: 'so', dialCode: '252' },
    'South Africa': { countryCode: 'za', dialCode: '27' },
    'Korea South': { countryCode: 'kr', dialCode: '82' },
    'South Sudan': { countryCode: 'ss', dialCode: '211' },
    Spain: { countryCode: 'es', dialCode: '34' },
    'Sri Lanka': { countryCode: 'lk', dialCode: '94' },
    Sudan: { countryCode: 'sd', dialCode: '249' },
    Suriname: { countryCode: 'sr', dialCode: '597' },
    'Svalbard And Jan Mayen Islands': { countryCode: 'sj', dialCode: '47' },
    Swaziland: { countryCode: 'sz', dialCode: '268' },
    Sweden: { countryCode: 'se', dialCode: '46' },
    Switzerland: { countryCode: 'ch', dialCode: '41' },
    'Syrian Arab Republic': { countryCode: 'sy', dialCode: '963' },
    Taiwan: { countryCode: 'tw', dialCode: '886' },
    Tajikistan: { countryCode: 'tj', dialCode: '992' },
    Tanzania: { countryCode: 'tz', dialCode: '255' },
    Thailand: { countryCode: 'th', dialCode: '66' },
    'Timor-leste': { countryCode: 'tl', dialCode: '670' },
    Togo: { countryCode: 'tg', dialCode: '228' },
    Tokelau: { countryCode: 'tk', dialCode: '690' },
    Tonga: { countryCode: 'to', dialCode: '676' },
    'Trinidad And Tobago': { countryCode: 'tt', dialCode: '1868' },
    Tunisia: { countryCode: 'tn', dialCode: '216' },
    Turkey: { countryCode: 'tr', dialCode: '90' },
    Turkmenistan: { countryCode: 'tm', dialCode: '993' },
    'Turks And Caicos Islands': { countryCode: 'tc', dialCode: '1649' },
    Tuvalu: { countryCode: 'tv', dialCode: '688' },
    'Virgin Islands (u.s.)': { countryCode: 'vi', dialCode: '1340' },
    Uganda: { countryCode: 'ug', dialCode: '256' },
    Ukraine: { countryCode: 'ua', dialCode: '380' },
    'United Arab Emirates': { countryCode: 'ae', dialCode: '971' },
    'United Kingdom': { countryCode: 'gb', dialCode: '44' },
    'United States': { countryCode: 'us', dialCode: '1' },
    Uruguay: { countryCode: 'uy', dialCode: '598' },
    Uzbekistan: { countryCode: 'uz', dialCode: '998' },
    Vanuatu: { countryCode: 'vu', dialCode: '678' },
    'Vatican City State': { countryCode: 'va', dialCode: '39' },
    Venezuela: { countryCode: 've', dialCode: '58' },
    'Viet Nam': { countryCode: 'vn', dialCode: '84' },
    'Wallis And Futuna Islands': { countryCode: 'wf', dialCode: '681' },
    'Western Sahara': { countryCode: 'eh', dialCode: '212' },
    Yemen: { countryCode: 'ye', dialCode: '967' },
    Zambia: { countryCode: 'zm', dialCode: '260' },
    Zimbabwe: { countryCode: 'zw', dialCode: '263' },
    'Aaland Islands': { countryCode: 'ax', dialCode: '358' }
  };

  constructor() { }

  getCountries() {
    return Object.keys(this.countries);
  }

  getCountryCode(countryName) {
    if (countryName) {
      return this.countries[countryName]?.countryCode;
    }
  }

  getPhonePrefix(countryName) {
    if (countryName) {
      return '+' + this.countries[countryName]?.dialCode;
    }
  }

  isValidDialCode(dialCode) {
    for (const country of Object.keys(this.countries)) {
      if ('+' + this.countries[country]?.dialCode ===  dialCode) {
        return true;
      }
    }

    return false;
  }
}
