import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class RoleAdminGuard  {

  constructor(@Inject(PLATFORM_ID) protected platformId, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const user = localStorage?.getItem('currentUser');
      if (isPlatformBrowser(this.platformId) && user) {
        if (user && JSON.parse(user) && JSON.parse(user).role === 'admin') {
          return true;
        }
      }
      this.router.navigateByUrl('/');
      return false;
   }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canActivate(next, state);
  }
}
