import { ILandingConfig } from '../../core/services/landing.service';
import { beautyLandingConfig } from './1-beautyConfig';

const configOverride: ILandingConfig = {
  pageTitle: 'trans:LANDING.landing.taglineBarbers',
  tagline: 'trans:LANDING.landing.taglineBarbers',
  heroImg: 'hero-barber.png',
  appImg: 'app-barber.png',
  featuresTitle: 'trans:LANDING.landing.featuresTitleBarber',
};

export const barbersLandingConfig: ILandingConfig = {
  ...beautyLandingConfig,
  ...configOverride,
  testemonies: [
    {
      text: 'trans:LANDING.landing.testemonies.testemonyBento',
      image: 'assets/av-david.jpg',
      person: 'David Frazão',
      buisiness: 'Bento Barbearias',
    },
    {
      text: 'trans:LANDING.landing.testemonies.testemonyPraga',
      image: 'assets/av-praga.jpg',
      person: 'André Teixeira',
      buisiness: 'Praga Barbearia',
    },
  ],
};
