import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UserService {

  public currentUser = new BehaviorSubject(null);

  constructor() {

  }

}
