export const environment = {
  version: '1.0.1',
  production: true,
  apiUrl: 'https://buk-api-staging.web.app/',
  noInternetErrorMsg: 'Ops, looks like your are offline...',
  defaultLanguage: 'pt',
  languages: [
    { code: 'pt', name: 'Português', supportEmail: 'suporte@buk.pt', contactPhone: '+351 21 123 0425', contactWhatsapp: '+351 965 541 732' },
    { code: 'es', name: 'Español', supportEmail: 'apoyo@buk.es', contactPhone: '+34 988 80 91 64', contactWhatsapp: '+34 65 55 32 080' },
    { code: 'en', name: 'English', supportEmail: 'support@buk.app', contactPhone: '', contactWhatsapp: '' },
  ],
  domains: [
    { host: 'buk-pt-staging.web.app', language: 'pt', defaultCountry: 'pt', demoLink: 'https://buk.pt/reunioes' },
    { host: 'buk-pt-staging.firebaseapp.com', language: 'en', defaultCountry: 'us', demoLink: 'https://calendly.com/bukdemo' },
    { host: 'staging.buk.es', language: 'es', defaultCountry: 'es', demoLink: 'https://buk.es/citas' },
    { host: 'localhost:5000', language: 'es', defaultCountry: 'es', demoLink: 'https://buk.es/reunioes' }
  ],
  maxSelfServiceNumberOfProviders: 20,
  facebookPixel: '563151531262339',
  facebookApp: {
    id: '2011833975753478',
    version: 'v19.0',
  },
  googleApp: {
    clientId: '1037644121283-5rp8s961319srp87u6iegjronctf972m.apps.googleusercontent.com',
  },
  stripe: {
    publishableKey: 'pk_test_AMREFpbZVJyQ8g7CWi6EQ3Nx',
  },
  firebase: {
    apiKey: 'AIzaSyDt9x1DDXc-o7HN0QsUBgUnZzGaY9TuDXw',
    authDomain: 'buk-pt.firebaseapp.com',
    databaseURL: 'https://buk-pt.firebaseio.com',
    projectId: 'buk-pt',
    storageBucket: 'buk-pt.appspot.com',
    messagingSenderId: '542739192323',
  },
  moloni: {
    id: 'buktestesapi',
    redirectUrl: 'https://buk-pt-staging.web.app/moloni/',
    authUrl: 'https://api.moloni.pt/v1/authorize/',
  },
  posthogKey: '',
  mixpanelKey: 'dummy',
  bugsnagKey: '1d6564970f42294234be640686d6c655',
  defaultColor: '#5055b7',
  cookieBot: {
    cbId: '636cd409-b3c5-4bc4-aa6e-e11549accdbb',
    blockingMode: 'auto'
  }
};
