
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { MerchantsService } from '../services/merchants.service';

@Injectable({
  providedIn: 'root'
})
export class HasActiveSubscriptionGuard  {
  constructor(private merchantService: MerchantsService, private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.merchantService.getMerchantFromMemoryOrApi().pipe(map((merchant) => {
      const canCancel = ['active', 'past_due'].includes(merchant.paymentStatus);
      if (canCancel) {
        return true;
      }
      this.router.navigate(['/dashboard']);
      return false;
    }));
  }

}
