import { ILandingConfig } from '../../core/services/landing.service';
import { healthLandingConfig } from './3-healthConfig';

const configOverride: ILandingConfig = {
  pageTitle: 'trans:LANDING.landing.taglinePhysio',
  tagline: 'trans:LANDING.landing.taglinePhysio',
  heroImg: 'hero-fisio.png',
  appImg: 'app-fisio.png',
  buisiness: 'trans:LANDING.landing.businesses.fisio',
  featuresTitle: 'trans:LANDING.landing.featuresTitlePhysio',
};

export const physiotherapistsLandingConfig: ILandingConfig = {
  ...healthLandingConfig,
  ...configOverride,
};
