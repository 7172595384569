import { Injectable } from '@angular/core';
import { addYears, endOfDay, format, isBefore, parse } from 'date-fns';
import { IntlService } from './intl.service';

@Injectable({
  providedIn: 'root',
})
export class MarketingTemplateService {
  private language = this.intlService.getCurrentLanguage();

  constructor(private intlService: IntlService) {}

  private templatesEn = [
    {
      "name": "Three Kings' Day",
      "emoji": "👑",
      "monthDay": "01-06",
      "date": null,
      "smsSuggestions": [
        "Celebrate Three Kings' Day in style! Enjoy a 10% discount on all our services. Book now!",
        "Treat yourself this Three Kings' Day with a special offer - 10% off our services. Book now!",
        "Kings and queens deserve the best! Enjoy a 10% discount on our services today.",
        "Make Three Kings' Day magical with a 10% discount on all services. Book now!",
        "Celebrate Three Kings' Day with a royal offer - 10% off on our services. Book now!"
      ],
      "emailSuggestions": [
        {
          "title": "Happy Three Kings' Day! 👑",
          "body": "Enjoy a royal discount of 10% on all our services."
        },
        {
          "title": "Celebrate like a King or Queen! 👑",
          "body": "This Three Kings' Day, enjoy 10% off our services."
        },
        {
          "title": "Royalty-worthy offers! 👑",
          "body": "Three Kings' Day special: 10% off on all services!"
        },
        {
          "title": "Kings and Queens, attention! 👑",
          "body": "Don't miss our special Three Kings' Day offer - 10% off!"
        },
        {
          "title": "An Unforgettable Three Kings' Day! 👑",
          "body": "Transform this Three Kings' Day with 10% off on all our services."
        }
      ]
    },
    {
      "name": "Carnival",
      "emoji": "🎉",
      "monthDay": "02-13",
      "date": null,
      "smsSuggestions": [
        "Carnival means celebration! Celebrate with a 10% discount on our services. Book now!",
        "Carnival joy with special discounts - 10% off all services. Book now!",
        "This Carnival, shine brighter with a 10% discount on our services. Book now!",
        "Celebrate Carnival in style - enjoy a 10% discount. Book now!",
        "Transform your Carnival with our services - now with a 10% discount. Book now!"
      ],
      "emailSuggestions": [
        {
          "title": "Celebrate Carnival in Style! 🎉",
          "body": "Enjoy a 10% discount on all our services."
        },
        {
          "title": "Carnival Discount Party! 🎉",
          "body": "Special Carnival offer - 10% off on our services."
        },
        {
          "title": "Shine this Carnival! 🎉",
          "body": "Carnival special discount: 10% off on all services!"
        },
        {
          "title": "Carnival with More Color! 🎉",
          "body": "Don't miss our Carnival promotion - 10% off."
        },
        {
          "title": "Carnival Joy with a Discount! 🎉",
          "body": "Celebrate Carnival with 10% off on all our services."
        }
      ]
    },
    {
      "name": "Valentine's Day",
      "emoji": "💖",
      "monthDay": "02-14",
      "date": null,
      "smsSuggestions": [
        "Love is in the air! Celebrate Valentine's Day with a 10% discount on our services. Book now!",
        "Surprise your loved one this Valentine's Day with a special gift - 10% off our services.",
        "Make this Valentine's Day unforgettable with a 10% discount on all services.",
        "Celebrate love with a special offer - 10% off our services this Valentine's Day.",
        "Valentine's Day is for pampering your loved one - enjoy our 10% discount on all services."
      ],
      "emailSuggestions": [
        {
          "title": "Celebrate Love! 💖 Valentine's Day",
          "body": "Enjoy our special 10% discount on all services."
        },
        {
          "title": "Love and Surprises! 💖",
          "body": "This Valentine's Day, we offer 10% off on our services."
        },
        {
          "title": "Love and Discount! 💖",
          "body": "Transform Valentine's Day with 10% off on all services."
        },
        {
          "title": "Special Offer for the Lovebirds! 💖",
          "body": "10% off on all our services this Valentine's Day."
        },
        {
          "title": "An Unforgettable Valentine's Day! 💖",
          "body": "Celebrate with your loved one and enjoy 10% off on our services."
        }
      ]
    },
    {
      "name": "Father's Day",
      "emoji": "👔",
      "monthDay": "03-19",
      "date": null,
      "smsSuggestions": [
        "Celebrate Father's Day with a special gift - 10% off on all our services.",
        "On Father's Day, show how much he means with 10% off on our services.",
        "Fathers deserve the best! Offer him a 10% discount on all services this Father's Day.",
        "Surprise your dad with a special gift - enjoy our 10% discount on all services.",
        "This Father's Day, offer more than words - 10% off on all our services."
      ],
      "emailSuggestions": [
        {
          "title": "Celebrate Father's Day! 👔",
          "body": "Special 10% discount on all our services."
        },
        {
          "title": "A Gift for the Best Dad! 👔",
          "body": "Special Father's Day offer - 10% off on our services."
        },
        {
          "title": "Father's Day with Love! 👔",
          "body": "Show your love with 10% off on all our services."
        },
        {
          "title": "Surprise Your Dad! 👔",
          "body": "Enjoy our special 10% discount this Father's Day."
        },
        {
          "title": "Special Dads Deserve Special Discounts! 👔",
          "body": "Offer him 10% off on all services this Father's Day."
        }
      ]
    },
    {
      "name": "Spring",
      "emoji": "🌸",
      "monthDay": "03-20",
      "date": null,
      "smsSuggestions": [
        "Celebrate the arrival of Spring with a 10% discount on our services. Book now!",
        "Welcome Spring with a special offer - 10% off on our services.",
        "Spring is a time for renewal! Enjoy a 10% discount on all services.",
        "Blossom this Spring with a 10% discount on all our services. Book now!",
        "Spring has arrived with discounts! 10% off on all services for you."
      ],
      "emailSuggestions": [
        {
          "title": "Welcome, Spring! 🌸",
          "body": "Celebrate the new season with a 10% discount on all our services."
        },
        {
          "title": "Spring in Bloom! 🌸",
          "body": "Special Spring discount - 10% off on all services."
        },
        {
          "title": "Renew this Spring! 🌸",
          "body": "Enjoy Spring with a 10% discount on all our services."
        },
        {
          "title": "Blossom with Discounts! 🌸",
          "body": "Spring has arrived with a special 10% discount."
        },
        {
          "title": "Celebrate Spring! 🌸",
          "body": "Enjoy a 10% discount on all our services this Spring."
        }
      ]
    },                    
    {
      name: 'April Fools',
      emoji: '🤥',
      monthDay: '04-01',
      date: null,
      smsSuggestions: [
        'We are not kidding! Today, and today only, we are offering a 10% discount on our services. Book now!',
        "Don't believe everything you hear - except for this offer! 10% discount on our services. Book now!",
        "This promotion is so good it seems like a lie - but it's not! 10% discount on all services. Book now!",
        'Lie? Truth? Who knows! Book one of our services today with a 10% discount! Book now!',
        "No, it's not a lie! we are offering a 10% discount on all services! Book now!",
      ],
      emailSuggestions: [
        {
          title: 'We are not kidding! 🤥',
          body: 'Today, and today only, we are offering a 10% discount on our services.',
        },
        {
          title: "Don't believe everything you hear...",
          body: "Don't believe everything you hear - except for this offer! 🤥 10% discount on our services.",
        },
        {
          title: 'Seems like a lie 🤥',
          body: "This promotion is so good it seems like a lie - but it's not! 🤥 10% discount on all services.",
        },
        {
          title: 'Lie? Truth? Who knows! 🤥',
          body: 'Book one of our services today with a 10% discount!',
        },
        {
          title: 'A lie??? 🤥',
          body: "No, it's not a lie! We are offering a 10% discount on all services!",
        },
      ],
    },
    {
      name: 'Easter',
      emoji: '🐣',
      monthDay: '03-31',
      date: null,
      smsSuggestions: [
        'Happy Easter! Enjoy a 10% discount on our services. Book now!',
        'This Easter, treat yourself to the best - enjoy a 10% discount on our services. Book now!',
        'To celebrate Easter, we are offering a 10% discount on all services! Book now!',
        "Don't miss our special Easter promotion - 10% discount on our services. Book now!",
        'Celebrate Easter with a special gift. Enjoy a 10% discount on all services! Book now!',
      ],
      emailSuggestions: [
        {
          title: 'Happy Easter! 🐣',
          body: 'Enjoy a 10% discount on our services.',
        },
        {
          title: 'Easter has arrived! 🐣',
          body: 'This Easter, treat yourself to the best - enjoy a 10% discount on our services.',
        },
        {
          title: "Let's celebrate Easter! 🐣",
          body: 'To celebrate Easter, we are offering a 10% discount on all services!',
        },
        {
          title: '10% discount! 🐣',
          body: "Don't miss our special Easter promotion - 10% discount on our services.",
        },
        {
          title: 'A special gift! 🐣',
          body: 'Celebrate Easter with a special gift. Enjoy a 10% discount on all services!',
        },
      ],
    },
    {
      name: "Mother's Day",
      emoji: '👩‍🍼',
      monthDay: '05-05',
      date: null,
      smsSuggestions: [
        "Happy Mother's Day! Enjoy a 10% discount on our services. Book now!",
        "A mother's love is eternal, and so is our love for them! Now 10% discount on all services. Book now!",
        "To celebrate Mother's Day, we are offering a 10% discount on all services! Book now!",
        "Special Mother's Day promotion - 10% discount on our services! Book now!",
        "Celebrate Mother's Day with a special gift. Enjoy a 10% discount on all services! Book now!",
      ],
      emailSuggestions: [
        {
          title: "Happy Mother's Day! 👩‍🍼",
          body: 'Enjoy a 10% discount on our services.',
        },
        {
          title: "A mother's love is eternal 👩‍🍼",
          body: "A mother's love is eternal, and so is our love for them! 👩‍🍼 Now 10% discount on all services.",
        },
        {
          title: "Let's celebrate Mother's Day! 👩‍🍼",
          body: "To celebrate Mother's Day, we are offering a 10% discount on all services!",
        },
        {
          title: '10% on all services',
          body: "Special Mother's Day promotion - enjoy 10% discount on our services now! 👩‍🍼",
        },
        {
          title: "Celebrate Mother's Day with a special gift. 👩‍🍼",
          body: "Celebrate Mother's Day with a special gift. 👩‍🍼 Enjoy a 10% discount on all services!",
        },
      ],
    },
    {
      name: '4th of July',
      emoji: '🇺🇸',
      monthDay: '07-04',
      date: null,
      smsSuggestions: [
        'Happy 4th of July! Book a service with us and get a 10% discount!',
        'Celebrate Independence Day with us! Enjoy a 10% discount on your next booking.',
        'Freedom deserves celebration! Book a service this 4th of July and enjoy a 10% discount! Book now!',
        "Let's celebrate Independence Day together! Get 10% off all our services when you book today!",
        'Happy Independence Day! Enjoy a special 10% discount on all our services. Book now!',
      ],
      emailSuggestions: [
        {
          title: 'Happy 4th of July! 🇺🇸',
          body: 'Book a service with us and get a 10% discount!',
        },
        {
          title: 'Celebrate Independence Day with a special offer! 🎇',
          body: 'Enjoy a 10% discount on your next booking.',
        },
        {
          title: 'Celebrate Freedom with a Discount! 🎆',
          body: 'Book a service this 4th of July and enjoy a 10% discount! Book now!',
        },
        {
          title: "Let's celebrate Independence Day together! 🇺🇸",
          body: 'Get 10% off all our services when you book today!',
        },
        {
          title: 'Happy Independence Day! 🎉',
          body: 'Enjoy a special 10% discount on all our services. Book now!',
        },
      ],
    },
    {
      name: 'Summer',
      emoji: '🌞',
      monthDay: '07-21',
      date: null,
      smsSuggestions: [
        'Summer has arrived! Get ready for the hottest and most lively season of the year! Schedule a service with us now and get a 10% discount!',
        'Stay cool this summer with our special promotion. Book a service of your choice and enjoy a 10% discount!',
        'The best time of the year is here: Summer! Choose a service of your choice and get an immediate 10% discount.',
        'Take advantage of the sun and heat for a change of look with 10% discount on all services of your choice. Book now!',
        'Go on vacation with a new look! Enjoy a 10% discount on all our services. Book now!',
      ],
      emailSuggestions: [
        {
          title: 'Summer has arrived! 🌞',
          body: 'Get ready for the hottest and most lively season of the year! Schedule a service with us now and get a 10% discount!',
        },
        {
          title: 'Stay cool with our promotion!',
          body: 'Stay cool this summer with our special promotion. Book a service of your choice and enjoy a 10% discount!',
        },
        {
          title: 'The best time of the year has arrived ⛱',
          body: 'The best time of the year is Summer! ⛱ Choose a service of your choice and get an immediate 10% discount.',
        },
        {
          title: 'The sun and heat have arrived! 🏖',
          body: 'Take advantage of the sun and heat for a change of look with 10% discount on all services of your choice. 🏖 Book now!',
        },
        {
          title: 'Go on vacation with a new look!',
          body: '👙 Enjoy a 10% discount on all our services. Book now!',
        },
      ],
    },
    {
      name: 'Back to School',
      emoji: '📚',
      monthDay: '09-20',
      date: null,
      smsSuggestions: [
        'Back to School special! Enjoy 10% off on our services. Book now!',
        'Kickstart the school year with a fresh look! Enjoy a 10% discount on our services. Book now!',
        'New school year, new you! Get 10% off all our services. Book now!',
        'Welcome the new academic year with a bang! Get 10% off when you book a service with us today.',
        'Get ready for the new term with a 10% discount on all our services. Book your appointment today!',
      ],
      emailSuggestions: [
        {
          title: 'Back to School Special! 📚',
          body: 'Enjoy 10% off on our services. Book now!',
        },
        {
          title: 'Kickstart the School Year with a Fresh Look! 🎒',
          body: 'Enjoy a 10% discount on our services. Book now!',
        },
        {
          title: 'New School Year, New You! 🏫',
          body: 'Get 10% off all our services. Book now!',
        },
        {
          title: 'Welcome the New Academic Year with a Bang! 💥',
          body: 'Get 10% off when you book a service with us today.',
        },
        {
          title: 'Get Ready for the New Term with a Discount! ✏️',
          body: 'Enjoy a 10% discount on all our services. Book your appointment today!',
        },
      ],
    },
    {
      name: 'Autumn',
      emoji: '🍂',
      monthDay: '09-23',
      date: null,
      smsSuggestions: [
        'Autumn has arrived! Prepare for this season and schedule a service with a 10% discount. Book now!',
        'Enjoy Autumn with us! Get a 10% discount on your next booking. Valid until the end of the season. We hope to see you soon!',
        'Stay trendy with the Autumn trends and take advantage of our special promotion. Book a service and enjoy a 10% discount!',
        'Autumn is a time for renewal. For this very reason, we are offering a 10% discount on all our services. Book now!',
        'Take advantage of Autumn for a change of look with a 10% discount on all services of your choice. Book now!',
      ],
      emailSuggestions: [
        {
          title: 'Autumn has arrived! 🍂',
          body: 'Prepare for this season and schedule a service with a 10% discount. Book now!',
        },
        {
          title: 'Enjoy Autumn with us! 🍂',
          body: 'Get a 10% discount on your next booking. Valid until the end of the season. We hope to see you soon!',
        },
        {
          title: 'Autumn Promotion 🍁',
          body: 'Stay trendy with the Autumn trends and take advantage of our special promotion. 🍁 Book a service and enjoy a 10% discount!',
        },
        {
          title: 'Treat yourself this Autumn 🍃',
          body: 'Autumn is a time for renewal 🍃 For this very reason, we are offering a 10% discount on all our services. Book now!',
        },
        {
          title: 'Change your look this Autumn 🍁',
          body: 'Take advantage of Autumn for a change of look with a 10% discount on all services of your choice. 🍁 Book now!',
        },
      ],
    },
    {
      name: 'Halloween',
      emoji: '👻',
      monthDay: '10-31',
      date: null,
      smsSuggestions: [
        'Show this text and get 10% off in any of our services. Enjoy our Halloween promotion!',
        'Spooky surprise! Present this text and receive 10% off all services offered.',
        'Celebrate Halloween with us! Show this message and get 10% off any service.',
        'The party starts here! Bring this message and get 10% off any service! Happy Halloween!',
        'Celebrate Halloween with us! Show this text and get 10% off any service.',
      ],
      emailSuggestions: [
        {
          title: 'Enjoy our Halloween promotion! 👻',
          body: 'Show this text and get 10% off in any of our services. 🎃 ',
        },
        {
          title: '👻 Spooky surprise!',
          body: 'Present this text and receive 10% off all services offered. 🎭🕯️',
        },
        {
          title: 'Celebrate Halloween with us! 🌙',
          body: 'Show this message and get 10% off any service. 🎃🍭',
        },
        {
          title: 'Happy Halloween! 🎃👻',
          body: '🎉 The party starts here! Bring this message and get 10% off any service!',
        },
        {
          title: 'Celebrate Halloween with us! 🌟',
          body: 'Show this text and get 10% off any service. 🕷️🍁',
        },
      ],
    },
    {
      name: 'Black friday',
      emoji: '🤑',
      monthDay: '11-29',
      date: null,
      smsSuggestions: [
        'Black Friday is here! Show this text and get 10% OFF on any service at our store!',
        'Exclusive Black Friday offer! Bring this text and get 10% OFF on all services. Dont miss out!',
        'Show this text to unlock your 10% discount on any service. Happy Black Friday!',
        'Unmissable Black Friday deal! Get 10% OFF on any procedure by showing this message. Enjoy!',
        'Celebrate Black Friday with us! Show this text and get 10% OFF on any service.',
      ],
      emailSuggestions: [
        {
          title: 'Black Friday is here! 🛍️',
          body: 'Show this text and get 10% OFF on any service at our store! 🎉💰',
        },
        {
          title: 'Exclusive Black Friday offer! 🎉',
          body: 'Bring this text and get 10% OFF on all services. Dont miss out! 💆‍♀️💆‍♂️',
        },
        {
          title: 'Happy Black Friday! 🎊💇‍♂️',
          body: 'Show this text to unlock your 10% 🏷️ discount on any service.',
        },
        {
          title: 'Unmissable Black Friday deal! 🚀',
          body: 'Get 10% OFF on any procedure by showing this message. Enjoy! 🎁💆‍♀️',
        },
        {
          title: 'Celebrate Black Friday with us! 🎊',
          body: 'Show this text and get 10% OFF on any service. 💆‍♂️🎁',
        },
      ],
    },
    {
      name: 'Cyber monday',
      emoji: '💻',
      monthDay: '12-02',
      date: null,
      smsSuggestions: [
        'Cyber Monday is here! Show this text and get 10% OFF on any service at our store!',
        'Exclusive Cyber Monday offer! Bring this text and get 10% OFF on all services. Dont miss out!',
        'Show this text to unlock your 10% discount on any service. Happy Cyber Monday!',
        'Unmissable Cyber Monday deal! Get 10% OFF on any procedure by presenting this text. Enjoy!',
        'Celebrate Cyber Monday with us! Show this text and get 10% OFF on any service.',
      ],
      emailSuggestions: [
        {
          title: 'Cyber Monday is here! 🛍️',
          body: 'Show this email and get 10% OFF on any service at our store! 🎉💰',
        },
        {
          title: 'Exclusive Cyber Monday offer! 🎉',
          body: 'Bring this email and get 10% OFF on all services. Dont miss out! 💆‍♀️💆‍♂️',
        },
        {
          title: 'Happy Cyber Monday! 🤑💻',
          body: 'Show this email to unlock your 10% 🏷️ discount on any service.',
        },
        {
          title: 'Unmissable Cyber Monday deal! 🚀',
          body: 'Get 10% OFF on any procedure by presenting this email. Enjoy! 🤑',
        },
        {
          title: 'Celebrate Cyber Monday with us! 🎊',
          body: 'Show this email and get 10% OFF on any service.',
        },
      ],
    },
    {
      name: 'Winter',
      emoji: '❄️',
      monthDay: '12-23',
      date: null,
      smsSuggestions: [
        'Show this text and warm up your Winter with 10% OFF on any service. Enjoy our promotion!',
        'Winter promotion! Bring this text and get 10% discount on any service.',
        'Heated discount! Show this text and get 10% off on any service during this Winter.',
        'Enjoy Winter with us! Show this text and get 10% off on any service. Warm up!',
        'Warm up with our Winter promotion! Show this text and get 10% off on all services.',
      ],
      emailSuggestions: [
        {
          title: 'Enjoy our promotion! 🔥',
          body: 'Show this text and warm up your Winter ❄️ with 10% OFF on any service.',
        },
        {
          title: 'Winter promotion! 🌨️',
          body: 'Bring this text and get 10% discount on any service. ❄️',
        },
        {
          title: 'Heated discount! 🔥',
          body: 'Show this text and get 10% off on any service during this Winter. 🛍️',
        },
        {
          title: 'Enjoy Winter with us! ❄️',
          body: 'Show this text and get 10% off on any service. Warm up! 🔥🏂',
        },
        {
          title: 'Warm up with our Winter promotion! 🧥',
          body: 'Show this text and get 10% off on all services. 🔥',
        },
      ],
    },
    {
      name: 'Christmas',
      emoji: '🎄',
      monthDay: '12-25',
      date: null,
      smsSuggestions: [
        'Merry Christmas! Enjoy our gift to you: 10% OFF on any service when you show this text.',
        'Treat yourself this Christmas! Present this text and save 10% on any service.',
        'Christmas with a discount! Bring this message and get 10% OFF on all services. Enjoy the season!',
        'The magic of Christmas has arrived! Get 10% discount by showing this text. Enjoy our services!',
        'Celebrate Christmas with us! Show this text and receive 10% OFF on any service.',
      ],
      emailSuggestions: [
        {
          title: 'Merry Christmas! 🎄',
          body: 'Enjoy our gift to you: 10% OFF on any service when you show this email. 🎁🎅',
        },
        {
          title: 'Treat yourself this Christmas! 🎁',
          body: 'Present this email and save 10% on any service. 🎅💆‍♀️',
        },
        {
          title: 'Christmas with a discount! 🎄',
          body: 'Bring this message and get 10% OFF on all services. Enjoy the season! 🎅',
        },
        {
          title: 'The magic of Christmas has arrived! 🌟',
          body: 'Get 10% discount by showing this email. Enjoy our services! 🎅💆‍♂️',
        },
        {
          title: 'Celebrate Christmas with us! ⛄',
          body: 'Show this email and receive 10% OFF on any service. 🎄🎉',
        },
      ],
    },
    {
      name: 'NYE',
      emoji: '🥂',
      monthDay: '01-01',
      date: null,
      smsSuggestions: [
        'Happy New Year! Start the new year with 10% OFF on all services when you show this text.',
        'Celebrate the New Year with us! Show this text and get 10% discount on any service.',
        'Toast to the new year with a special discount! Present this text and enjoy 10% off on any service.',
        'Celebrate the New Year with us! Show this text and receive 10% OFF on any service.',
        'New Years gift! Bring this text and get 10% discount on all services.',
      ],
      emailSuggestions: [
        {
          title: 'Happy New Year! 🎉',
          body: 'Start the new year with 10% OFF on all services when you show this text. 🎇🥳',
        },
        {
          title: 'Celebrate the New Year with us! 🌟',
          body: 'Show this text and get 10% discount on any service. 🎆🎁',
        },
        {
          title: 'Special discount! 🥂',
          body: 'Toast to the new year with a special discount! 🥂 Present this text and get 10% off on any service. 🎇🎁',
        },
        {
          title: 'Celebrate the New Year with us! 🍾',
          body: 'Show this text and receive 10% OFF on any service. 🎊💇‍♂️',
        },
        {
          title: 'New Years gift! 🎁',
          body: 'Bring this text and get 10% discount on all services. 🎇🎊',
        },
      ],
    },
  ];

  private templatesPt = [
    {
      "name": "Dia dos Reis",
      "emoji": "👑",
      "monthDay": "01-06",
      "date": null,
      "smsSuggestions": [
        "Celebre o Dia dos Reis com estilo! Aproveite 10% de desconto em todos os nossos serviços. Marque já!",
        "Neste Dia dos Reis, presenteie-se com algo especial - 10% de desconto nos nossos serviços. Marque agora!",
        "Reis e rainhas merecem o melhor! Desfrute de um desconto de 10% em nossos serviços hoje!",
        "Transforme o Dia dos Reis em algo mágico com 10% de desconto em todos os serviços. Marque agora!",
        "Comemore o Dia dos Reis com uma oferta real - 10% de desconto em nossos serviços. Marque já!"
      ],
      "emailSuggestions": [
        {
          "title": "Feliz Dia dos Reis! 👑",
          "body": "Desfrute de um desconto real de 10% em todos os nossos serviços."
        },
        {
          "title": "Celebre como um Rei ou Rainha! 👑",
          "body": "Neste Dia dos Reis, aproveite 10% de desconto em nossos serviços."
        },
        {
          "title": "Ofertas dignas de realeza! 👑",
          "body": "Especial Dia dos Reis: 10% de desconto em todos os serviços!"
        },
        {
          "title": "Reis e Rainhas, atenção! 👑",
          "body": "Não perca nossa oferta especial de Dia dos Reis - 10% de desconto!"
        },
        {
          "title": "Um Dia dos Reis inesquecível! 👑",
          "body": "Transforme este Dia dos Reis com 10% de desconto em todos os nossos serviços."
        }
      ]
    },
    {
      "name": "Carnaval",
      "emoji": "🎉",
      "monthDay": "02-13",
      "date": null,
      "smsSuggestions": [
        "Carnaval é sinônimo de festa! Celebre com 10% de desconto em nossos serviços. Marque agora!",
        "Alegria de Carnaval com descontos especiais - 10% off em todos os serviços. Marque já!",
        "Neste Carnaval, brilhe mais com 10% de desconto em nossos serviços. Marque agora!",
        "Festeje o Carnaval em grande estilo - aproveite 10% de desconto. Marque já!",
        "Transforme seu Carnaval com nossos serviços - agora com 10% de desconto. Marque agora!"
      ],
      "emailSuggestions": [
        {
          "title": "Celebre o Carnaval em grande estilo! 🎉",
          "body": "Aproveite 10% de desconto em todos os nossos serviços."
        },
        {
          "title": "Festa de Carnaval com desconto! 🎉",
          "body": "Oferta especial de Carnaval - 10% de desconto em nossos serviços."
        },
        {
          "title": "Brilhe neste Carnaval! 🎉",
          "body": "Desconto especial de Carnaval: 10% off em todos os serviços!"
        },
        {
          "title": "Carnaval com mais cor! 🎉",
          "body": "Não perca nossa promoção de Carnaval - 10% de desconto."
        },
        {
          "title": "Alegria de Carnaval com desconto! 🎉",
          "body": "Celebre o Carnaval com 10% de desconto em todos os nossos serviços."
        }
      ]
    },
    {
      "name": "Dia dos Namorados",
      "emoji": "💖",
      "monthDay": "02-14",
      "date": null,
      "smsSuggestions": [
        "Amor está no ar! Celebre o Dia dos Namorados com 10% de desconto nos nossos serviços. Marque já!",
        "Surpreenda sua cara-metade neste Dia dos Namorados com um presente especial - 10% de desconto nos nossos serviços.",
        "Faça deste Dia dos Namorados um momento inesquecível com 10% de desconto em todos os serviços.",
        "Celebre o amor com uma oferta especial - 10% de desconto em nossos serviços neste Dia dos Namorados.",
        "Dia dos Namorados é para mimar quem ama - aproveite nosso desconto de 10% em todos os serviços."
      ],
      "emailSuggestions": [
        {
          "title": "Celebre o amor! 💖 Dia dos Namorados",
          "body": "Aproveite nosso desconto especial de 10% em todos os serviços."
        },
        {
          "title": "Surpresas de amor! 💖",
          "body": "Neste Dia dos Namorados, oferecemos 10% de desconto em nossos serviços."
        },
        {
          "title": "Amor e Desconto! 💖",
          "body": "Transforme o Dia dos Namorados com 10% de desconto em todos os serviços."
        },
        {
          "title": "Oferta especial para os apaixonados! 💖",
          "body": "Desconto de 10% em todos os nossos serviços neste Dia dos Namorados."
        },
        {
          "title": "Dia dos Namorados inesquecível! 💖",
          "body": "Celebre com quem ama e aproveite 10% de desconto em nossos serviços."
        }
      ]
    },
    {
      "name": "Dia do Pai",
      "emoji": "👔",
      "monthDay": "03-19",
      "date": null,
      "smsSuggestions": [
        "Celebre o Dia do Pai com um presente especial - 10% de desconto em todos os nossos serviços.",
        "No Dia do Pai, mostre o quanto ele é importante com 10% de desconto nos nossos serviços.",
        "Pais merecem o melhor! Ofereça-lhe 10% de desconto em todos os serviços neste Dia do Pai.",
        "Surpreenda seu pai com um presente especial - aproveite nosso desconto de 10% em todos os serviços.",
        "Neste Dia do Pai, ofereça mais do que palavras - 10% de desconto em todos os nossos serviços."
      ],
      "emailSuggestions": [
        {
          "title": "Celebre o Dia do Pai! 👔",
          "body": "Desconto especial de 10% em todos os nossos serviços."
        },
        {
          "title": "Um presente para o melhor pai! 👔",
          "body": "Oferta especial de Dia do Pai - 10% de desconto em nossos serviços."
        },
        {
          "title": "Dia do Pai com carinho! 👔",
          "body": "Mostre seu amor com 10% de desconto em todos os nossos serviços."
        },
        {
          "title": "Surpreenda seu pai! 👔",
          "body": "Aproveite nosso desconto especial de 10% neste Dia do Pai."
        },
        {
          "title": "Pais especiais merecem descontos especiais! 👔",
          "body": "Ofereça-lhe 10% de desconto em todos os serviços neste Dia do Pai."
        }
      ]
    },
    {
      "name": "Primavera",
      "emoji": "🌸",
      "monthDay": "03-20",
      "date": null,
      "smsSuggestions": [
        "Celebre a chegada da Primavera com 10% de desconto em nossos serviços. Marque agora!",
        "Dê as boas-vindas à Primavera com uma oferta especial - 10% de desconto nos nossos serviços.",
        "Primavera é tempo de renovação! Aproveite 10% de desconto em todos os serviços.",
        "Floresça nesta Primavera com 10% de desconto em todos os nossos serviços. Marque já!",
        "Primavera chegou e trouxe descontos! 10% off em todos os serviços para você."
      ],
      "emailSuggestions": [
        {
          "title": "Bem-vinda, Primavera! 🌸",
          "body": "Celebre a nova estação com 10% de desconto em todos os nossos serviços."
        },
        {
          "title": "Primavera em flor! 🌸",
          "body": "Desconto especial de Primavera - 10% off em todos os serviços."
        },
        {
          "title": "Renove-se nesta Primavera! 🌸",
          "body": "Aproveite a Primavera com 10% de desconto em todos os nossos serviços."
        },
        {
          "title": "Floresça com descontos! 🌸",
          "body": "Primavera chegou e com ela um desconto especial de 10%."
        },
        {
          "title": "Celebre a Primavera! 🌸",
          "body": "Desfrute de 10% de desconto em todos os nossos serviços nesta Primavera."
        }
      ]
    },                
    {
      name: 'Dia das mentiras',
      emoji: '🤥',
      monthDay: '04-01',
      date: null,
      smsSuggestions: [
        'Não estamos a brincar! Hoje, e só hoje, oferecemos 10% de desconto nos nossos serviços. Marque agora!',
        'Não acredite em tudo o que ouve - exceto nesta oferta! 10% de desconto nos nossos serviços. Marque agora!',
        'Esta promoção é tão boa que parece mentira - mas não é! 10% de desconto em todos os serviços. Marque agora!',
        'Mentira? Verdade? Quem sabe! Marque um dos nossos serviços hoje com 10% de desconto! Marque agora!',
        'Não, não é mentira! Estamos a oferecer um desconto de 10% em todos os serviços! Marque já!',
      ],
      emailSuggestions: [
        {
          title: 'Não estamos a brincar! 🤥',
          body: 'Hoje, e só hoje, oferecemos 10% de desconto nos nossos serviços.',
        },
        {
          title: 'Não acredite em tudo o que ouve...',
          body: 'Não acredite em tudo o que ouve - exceto nesta oferta! 🤥 10% de desconto nos nossos serviços.',
        },
        {
          title: 'Parece mentira 🤥',
          body: 'Esta promoção é tão boa que parece mentira - mas não é! 🤥 10% de desconto em todos os serviços.',
        },
        {
          title: 'Mentira? Verdade? Quem sabe! 🤥',
          body: 'Marque um dos nossos serviços hoje com 10% de desconto!',
        },
        {
          title: 'Mentira??? 🤥',
          body: 'Não, não é mentira! estamos a oferecer um desconto de 10% em todos os serviços!',
        },
      ],
    },
    {
      name: 'Páscoa',
      emoji: '🐣',
      monthDay: '03-31',
      date: null,
      smsSuggestions: [
        'Feliz Páscoa! aproveite 10% de desconto nos nossos serviços. Marque agora!',
        'Nesta Páscoa, presenteie-se com o melhor - aproveite 10% de desconto nos nossos serviços. Marque agora!',
        'Para celebrar a Páscoa, estamos a oferecer um desconto de 10% em todos os serviços! Marque já!',
        'Não perca a nossa promoção especial de Páscoa - 10% de desconto nos nossos serviços. Marque agora!',
        'Comemore a Páscoa com um presente especial. Aproveite 10% de desonto em todos os serviços! Marque agora!',
      ],
      emailSuggestions: [
        {
          title: 'Feliz Páscoa! 🐣',
          body: 'Aproveite 10% de desconto nos nossos serviços.',
        },
        {
          title: 'Chegou a Páscoa! 🐣',
          body: 'Nesta Páscoa, presenteie-se com o melhor - aproveite 10% de desconto nos nossos serviços.',
        },
        {
          title: 'Vamos celebrar a Páscoa! 🐣',
          body: 'Para celebrar a Páscoa, estamos a oferecer um desconto de 10% em todos os serviços!',
        },
        {
          title: '10% de desconto! 🐣',
          body: 'Não perca a nossa promoção especial de Páscoa - 10% de desconto nos nossos serviços.',
        },
        {
          title: 'Um presente especial! 🐣',
          body: 'Comemore a Páscoa com um presente especial. Aproveite 10% de desonto em todos os serviços!',
        },
      ],
    },
    {
      name: 'Dia da mãe',
      emoji: '👩‍🍼',
      monthDay: '05-05',
      date: null,
      smsSuggestions: [
        'Feliz dia da mãe! Aproveite 10% de desconto nos nossos serviços. Marque agora!',
        'O amor de mãe é eterno e nosso amor por elas também! Agora 10% de desconto em todos os serviços. Marque já!',
        'Para celebrar o dia da mãe, estamos a oferecer um desconto de 10% em todos os serviços! Marque agora!',
        'Promoção especial do dia da mãe - 10% de desconto nos nossos serviços! Marque agora!',
        'Comemore o dia da mãe com um presente especial. Aproveite 10% de desconto em todos os serviços! Marque agora!',
      ],
      emailSuggestions: [
        {
          title: 'Feliz dia da mãe! 👩‍🍼',
          body: 'Aproveite 10% de desconto nos nossos serviços.',
        },
        {
          title: 'O amor de mãe é eterno 👩‍🍼',
          body: 'O amor de mãe é eterno e nosso amor por elas também! 👩‍🍼 Agora 10% de desconto em todos os serviços.',
        },
        {
          title: 'Vamos celebrar o dia da mãe! 👩‍🍼',
          body: 'Para celebrar o dia da mãe, estamos a oferecer um desconto de 10% em todos os serviços! ',
        },
        {
          title: '10% em todos os serviços',
          body: 'Promoção especial do dia das mães - aproveite agora 10% de desconto nos nossos serviços! 👩‍🍼',
        },
        {
          title: 'Comemore o dia da mãe com um presente especial. 👩‍🍼',
          body: 'Comemore o dia da mãe com um presente especial. 👩‍🍼 Aproveite 10% de desconto em todos os serviços!',
        },
      ],
    },
    {
      name: 'Santos populares',
      emoji: '🎉',
      monthDay: '06-13',
      date: null,
      smsSuggestions: [
        'Agende o seu horário conosco e aproveite um desconto de 10% em todos os nossos serviços para os Santos Populares.Marque já!',
        'Aproveite a nossa promoção de 10% em todos os serviços e venha fazer a barba para as festas dos Santos Populares! Garanta um visual impecável para os festejos!',
        'Viva os Santos Populares! É tempo de celebrar com sardinhas, marchas populares e ainda uma promoção 10% em todos os nossos serviços. Marque agora!',
        'Celebre as festas dos nossos santos populares com muito estilo! Agende agora um serviço conosco e ganhe 10% de desconto! Marque já!',
        'Chegou a época mais animada do ano! Celebre conosco os Santos Popoulares e garanta o seu visual favorito com 10% de desconto em todos os serviços. Marque já!',
      ],
      emailSuggestions: [
        {
          title: '🌟 Desconto de 10% em todos os nossos serviços para os Santos Populares 🌟',
          body: 'Agende o seu horário conosco e aproveite os descontos de beleza para o Santo Antonio, São João e São Pedro! 🎉 Marque já!',
        },
        {
          title: 'Promoção especial para as festas dos Santos Populares!',
          body: '👉 Aproveite a nossa promoção especial e venha fazer a barba para as festas de Santo António, São João e São Pedro! Garanta um visual impecável para os festejos com 10% de desconto durante todo o mês de Junho! 💈✂️🧔',
        },
        {
          title: 'Viva os Santos Populares! ',
          body: 'É tempo de celebrar com sardinhas, marchas populares e ainda uma promoção 10% em todos os nossos serviços. 🎉 Marque agora!',
        },
        {
          title: 'Junho é o mês mais aguardado do ano em Portugal!',
          body: 'Celebre as festas dos nossos santos populares com muito estilo! Agende agora um serviço conosco e ganhe 10% de desconto! 🎉 Marque já!',
        },
        {
          title: '🎉 Chegou a época mais animada do ano! 🎉 ',
          body: 'Celebre conosco as festas de Santo António, São João e São Pedro e garanta o seu visual favorito com 10% de desconto em todos os serviços. Marque agora!',
        },
      ],
    },
    {
      name: 'Verão',
      emoji: '🌞',
      monthDay: '07-21',
      date: null,
      smsSuggestions: [
        'O Verão chegou! Prepare-se para a estação mais quente e animada do ano! Agende agora um serviço conosco e ganhe 10% de desconto! ',
        'Mantenha-se fresco neste Verão com a nossa promoção especial. Marque um serviço à sua escolha e usufrua de 10% de desconto!',
        'Chegou a melhor altura do ano: o Verão! Escolha um serviço à sua escolha e tenha 10% de desconto imediato.',
        'Aproveite o sol e o calor para uma mudança de visual com 10% de desconto em todos os serviços à sua escolha. Marque já!',
        'Vá de férias com um novo look! Aproveite um desconto de 10% em todos os nossos serviços. Marque agora!',
      ],
      emailSuggestions: [
        {
          title: 'O Verão chegou! 🌞 ',
          body: 'Prepare-se para a estação mais quente e animada do ano! Agende agora um serviço conosco e ganhe 10% de desconto!',
        },
        {
          title: 'Mantenha-se fresco com a nossa promoção!',
          body: 'Mantenha-se fresco neste Verão com a nossa promoção especial. Marque um serviço à sua escolha e usufrua de 10% de desconto!',
        },
        {
          title: 'Chegou a melhor altura do ano ⛱',
          body: 'A melhor altura do ano é o Verão! ⛱ Escolha um serviço à sua escolha e tenha 10% de desconto imediato.',
        },
        {
          title: 'Chegou o sol e o calor! 🏖',
          body: 'Aproveite o sol e o calor para uma mudança de visual com 10% de desconto em todos os serviços à sua escolha. 🏖 Marque já!',
        },
        {
          title: 'Vá de férias com um novo look! ',
          body: '👙 Aproveite um desconto de 10% em todos os nossos serviços. Marque agora!',
        },
      ],
    },
    {
      name: 'Férias',
      emoji: '🏖',
      monthDay: '08-30',
      date: null,
      smsSuggestions: [
        'Iremos encerrar de X a X para as nossas férias anuais. Agradecemos a sua compreensão!',
        'Iremos encerrar para férias de Verão de X a X. Voltaremos em breve!',
        'Estaremos ausentes entre X e X devido às nossas férias de Verão. Aguardamos o seu regresso!',
        'Durante o período de X a X estaremos encerrados para aproveitar o Verão. Esperamos atendê-lo em breve!',
        'Fechados para férias de X a X. Em breve estaremos de volta para melhor atendê-lo!'
      ],
      emailSuggestions: [
        {
          title: 'Fechados para as nossas férias anuais de Verão! 🌞 ',
          body: 'Caro cliente, gostaríamos de informar que estaremos encerrados de X a X para as nossas férias anuais de Verão. Agradecemos a sua compreensão e esperamos vê-lo em breve!'
        },
        {
          title: 'Aviso de encerramento para férias de Verão',
          body: 'Prezado cliente, informamos que estaremos ausentes entre X e X devido às nossas férias de Verão. Durante este período, não estaremos disponíveis para atendimento. Agradecemos pela sua compreensão e aguardamos o seu regresso!'
        },
        {
          title: 'Encerramento temporário devido às férias de Verão',
          body: 'Estimado cliente, queremos informar que estaremos encerrados para férias de Verão de X a X. Durante esse período, não estaremos operacionais. Esperamos continuar a atendê-lo com excelência após o nosso regresso!'
        },
        {
          title: 'Aviso de fechamento durante o Verão',
          body: 'Caro cliente, estaremos fechados para férias de Verão entre X e X. Agradecemos pela sua preferência e aguardamos ansiosamente para retomar os nossos serviços assim que voltarmos. Até breve!'
        },
        {
          title: 'Fechados para férias de X a X',
          body: 'Prezado cliente, estaremos encerrados para férias de X a X. Durante esse período, não estaremos disponíveis para atendimento. Agradecemos pela compreensão e esperamos continuar a atendê-lo em breve!'
        }
      ]
    },
    {
      name: 'Regresso às Aulas',
      emoji: '📚',
      monthDay: '09-20',
      date: null,
      smsSuggestions: [
        'Especial Regresso às Aulas! Aproveite 10% de desconto nos nossos serviços. Marque agora!',
        'Comece o ano letivo com um novo visual! Aproveite um desconto de 10% nos nossos serviços. Marque já!',
        'Novo ano letivo, novo visual! Ganhe 10% de desconto em todos os nossos serviços. Marque agora!',
        'Receba o novo ano letivo em grande estilo! Receba 10% de desconto ao marcar um serviço connosco hoje.',
        'Prepare-se para o novo semestre com 10% de desconto em todos os nossos serviços. Marque a sua consulta hoje!',
      ],
      emailSuggestions: [
        {
          title: 'Especial Regresso às Aulas! 📚',
          body: 'Aproveite 10% de desconto nos nossos serviços. Marque agora!',
        },
        {
          title: 'Comece o Ano Letivo com um Novo Visual! 🎒',
          body: 'Aproveite um desconto de 10% nos nossos serviços. Marque já!',
        },
        {
          title: 'Novo Ano Letivo, Novo Visual! 🏫',
          body: 'Ganhe 10% de desconto em todos os nossos serviços. Marque agora!',
        },
        {
          title: 'Receba o Novo Ano Letivo em Grande Estilo! 💥',
          body: 'Receba 10% de desconto ao marcar um serviço connosco hoje.',
        },
        {
          title: 'Prepare-se Para o Novo Semestre com um Desconto! ✏️',
          body: 'Aproveite 10% de desconto em todos os nossos serviços. Marque a sua consulta hoje!',
        },
      ],
    },
    {
      name: 'Outono',
      emoji: '🍂',
      monthDay: '09-23',
      date: null,
      smsSuggestions: [
        'O Outono chegou! Prepare-se para esta estação e agende um serviço com 10% de desconto. Marque já!',
        'Aproveite o Outono connosco! Ganhe 10% de desconto na sua próxima marcação Válido até o final da estação. Esperamos vê-lo em breve!',
        'Mantenha-se na moda com as tendências de Outuno e aproveite a nossa promoção especial. Marque um serviço e usufrua de 10% de desconto!',
        'Outono é altura de renovação Por isso mesmo estamos a oferecer 10% de desconto em todos os nossos serviços. Marque já!',
        'Aproveite o Outono para uma mudança de visual com 10% de desconto em todos os serviços à sua escolha. Marque já!',
      ],
      emailSuggestions: [
        {
          title: 'O Outono chegou! 🍂',
          body: 'Prepare-se para esta estação e agende um serviço com 10% de desconto. Marque já!',
        },
        {
          title: 'Aproveite o Outono connosco! 🍂 ',
          body: 'Ganhe 10% de desconto na sua próxima marcação Válido até o final da estação. Esperamos vê-lo em breve!',
        },
        {
          title: 'Promoção de Outono 🍁',
          body: 'Mantenha-se na moda com as tendências de Outuno e aproveite a nossa promoção especial. 🍁 Marque um serviço e usufrua de 10% de desconto!',
        },
        {
          title: 'Este Outono mime-se 🍃',
          body: 'Outono é altura de renovação 🍃 Por isso mesmo estamos a oferecer 10% de desconto em todos os nossos serviços. Marque já!',
        },
        {
          title: 'Este Outono mude de visual 🍁',
          body: 'Aproveite o Outono para uma mudança de visual com 10% de desconto em todos os serviços à sua escolha. 🍁 Marque já!',
        },
      ],
    },
    {
      name: 'Halloween',
      emoji: '👻',
      monthDay: '10-31',
      date: null,
      smsSuggestions: [
        'Mostre este SMS e obtenha 10% de desconto em qualquer serviço de beleza em nosso salão. Aproveite nossa promoção de Halloween!',
        'Surpresa arrepiante! Apresente este SMS e receba 10% de desconto em todos os serviços oferecidos.',
        'Celebre o Halloween conosco! Mostre esta msg e ganhe 10% OFF em qualquer serviço.',
        'A festa começa aqui! Traga esta msg e ganhe 10% de desconto em qualquer serviço! Feliz Halloween!',
        'Celebre o Dia das Bruxas conosco! Mostre este SMS e obtenha 10% OFF em qualquer serviço.',
      ],
      emailSuggestions: [
        {
          title: 'Aproveite nossa promoção de Halloween! 👻',
          body: '🎃 Mostre este SMS e obtenha 10% de desconto em qualquer serviço de beleza em nosso salão.',
        },
        {
          title: '👻 Surpresa arrepiante!',
          body: 'Apresente este SMS e receba 10% de desconto em todos os serviços oferecidos. 🎭🕯️',
        },
        {
          title: 'Celebre o Halloween conosco! 🌙 ',
          body: 'Mostre esta msg e ganhe 10% OFF em qualquer serviço. 🎃🍭',
        },
        {
          title: 'Feliz Halloween! 🎃👻',
          body: '🎉 A festa começa aqui! Traga esta msg e ganhe 10% de desconto em qualquer serviço!',
        },
        {
          title: 'Celebre o Dia das Bruxas conosco! 🌟',
          body: 'Mostre este SMS e obtenha 10% OFF em qualquer serviço. 🕷️🍁',
        },
      ],
    },
    {
      name: 'Black friday',
      emoji: '🤑',
      monthDay: '11-29',
      date: null,
      smsSuggestions: [
        'O Black Friday chegou! Mostre este SMS e ganhe 10% OFF em qualquer serviço em nossa loja!',
        'Oferta exclusiva de Black Friday! Traga este SMS e receba 10% OFF em todos os serviços. Não perca!',
        'Mostre este SMS para desbloquear seu desconto de 10% em qualquer serviço. Feliz Black Friday!',
        'Black Friday imperdível! Ganhe 10% OFF em qualquer procedimento ao apresentar esta msg. Aproveite!',
        'Celebre a Black Friday conosco! Mostre esta msg e ganhe 10% OFF em qualquer serviço.',
      ],
      emailSuggestions: [
        {
          title: 'O Black Friday chegou! 🛍️',
          body: 'Mostre este email e ganhe 10% OFF em qualquer serviço em nossa loja! 🎉💰',
        },
        {
          title: 'Oferta exclusiva de Black Friday! 🎉',
          body: 'Traga este email e receba 10% OFF em todos os serviços. Não perca! 🤑',
        },
        {
          title: 'Feliz Black Friday! 🎊💇‍♂️',
          body: 'Mostre este email para desbloquear seu desconto de 10% 🏷️ em qualquer serviço.',
        },
        {
          title: 'Black Friday imperdível! 🚀',
          body: 'Ganhe 10% OFF em qualquer procedimento ao apresentar este email. Aproveite! 🤑',
        },
        {
          title: 'Celebre a Black Friday conosco! 🎊',
          body: 'Mostre este email e ganhe 10% OFF em qualquer serviço. 🎁',
        },
      ],
    },
    {
      name: 'Cyber monday',
      emoji: '💻',
      monthDay: '12-02',
      date: null,
      smsSuggestions: [
        'O Cyber Monday chegou! Mostre este SMS e ganhe 10% OFF em qualquer serviço em nossa loja!',
        'Oferta exclusiva Cyber Monday! Traga este SMS e receba 10% OFF em todos os serviços. Não perca!',
        'Mostre este SMS para desbloquear seu desconto de 10% em qualquer serviço. Viva a Cyber Monday!',
        'Cyber Monday imperdível! Ganhe 10% OFF em qualquer procedimento ao apresentar esta msg. Aproveite!',
        'Celebre a Cyber Monday conosco! Mostre esta msg e ganhe 10% OFF em qualquer serviço.',
      ],
      emailSuggestions: [
        {
          title: 'O Cyber Monday chegou! 🛍️',
          body: 'Mostre este email e ganhe 10% OFF em qualquer serviço em nossa loja! 🎉💰',
        },
        {
          title: 'Oferta exclusiva Cyber Monday! 🎉',
          body: 'Traga este email e receba 10% OFF em todos os serviços. Não perca! 💆‍♀️💆‍♂️',
        },
        {
          title: 'Feliz Cyber Monday! 🤑💻',
          body: 'Mostre este email para desbloquear seu desconto de 10% 🏷️ em qualquer serviço.',
        },
        {
          title: 'Cyber Monday imperdível! 🚀',
          body: 'Ganhe 10% OFF em qualquer procedimento ao apresentar este email. Aproveite! 🤑',
        },
        {
          title: 'Celebre a Cyber Monday conosco! 🎊',
          body: 'Mostre este email e ganhe 10% OFF em qualquer serviço. ',
        },
      ],
    },
    {
      name: 'Inverno',
      emoji: '❄️',
      monthDay: '12-23',
      date: null,
      smsSuggestions: [
        'Mostre este SMS e aqueça o seu Inverno com 10% OFF em qualquer serviço. Aproveite nossa promoção!',
        'Promoção de Inverno! Traga este SMS e obtenha 10% de desconto em qualquer serviço.',
        'Desconto aquecido! Mostre este SMS e ganhe 10% de desconto em qualquer serviço durante este Inverno.',
        'Aproveite o Inverno conosco! Mostre este SMS e ganhe 10% de desconto em qualquer serviço. Aqueça-se!',
        'Aqueça-se com nossa promoção de Inverno! Mostre este SMS e receba 10% de desconto em todos os serviços.',
      ],
      emailSuggestions: [
        {
          title: 'Aproveite nossa promoção! 🔥',
          body: 'Mostre este email e aqueça o seu Inverno ❄️ com 10% OFF em qualquer serviço.',
        },
        {
          title: 'Promoção de Inverno! 🌨️',
          body: 'Traga este email e obtenha 10% de desconto em qualquer serviço. ❄️',
        },
        {
          title: 'Desconto aquecido! 🔥',
          body: 'Mostre este email e ganhe 10% de desconto em qualquer serviço durante este Inverno. 🛍️',
        },
        {
          title: 'Aproveite o Inverno conosco! ❄️',
          body: 'Mostre este email e ganhe 10% de desconto em qualquer serviço. Aqueça-se! 🔥🏂',
        },
        {
          title: 'Aqueça-se com nossa promoção de Inverno! 🧥',
          body: 'Mostre este email e receba 10% de desconto em todos os serviços. 🔥',
        },
      ],
    },
    {
      name: 'Natal',
      emoji: '🎄',
      monthDay: '12-25',
      date: null,
      smsSuggestions: [
        'Feliz Natal! Aproveite nosso presente para você: 10% OFF em qualquer serviço ao mostrar este SMS.',
        'Presenteie-se neste Natal! Apresente este SMS e economize 10% em qualquer serviço.',
        'Natal com desconto! Traga esta msg e obtenha 10% OFF em todos os serviços. Aproveite a temporada!',
        'A magia do Natal chegou! Ganhe 10% de desconto ao mostrar esta msg. Aproveite os nossos serviços!',
        'Celebre o Natal conosco! Mostre este SMS e receba 10% OFF em qualquer serviço.',
      ],
      emailSuggestions: [
        {
          title: 'Feliz Natal! 🎄',
          body: 'Aproveite nosso presente para você: 10% OFF em qualquer serviço ao mostrar este email. 🎁🎅',
        },
        {
          title: 'Presenteie-se neste Natal! 🎁',
          body: 'Apresente este email e economize 10% em qualquer serviço. 🎅💆‍♀️',
        },
        {
          title: 'Natal com desconto! 🎄',
          body: 'Traga esta mensagem e obtenha 10% OFF em todos os serviços. Aproveite a temporada! 🎅',
        },
        {
          title: 'A magia do Natal chegou! 🌟',
          body: 'Ganhe 10% de desconto ao mostrar esta mensagem. Aproveite os nossos serviços! 🎅💆‍♂️',
        },
        {
          title: 'Celebre o Natal conosco! ⛄',
          body: 'Mostre este email e receba 10% OFF em qualquer serviço. 🎄🎉',
        },
      ],
    },
    {
      name: 'Ano novo',
      emoji: '🥂',
      monthDay: '01-01',
      date: null,
      smsSuggestions: [
        'Feliz Ano Novo! Comece o novo ano com 10% OFF em todos os serviços ao mostrar este SMS.',
        'Celebre o Ano Novo conosco! Mostre esta msg e ganhe 10% de desconto em qualquer serviço.',
        'Brinde ao novo ano com desconto especial! Apresente este SMS e aproveite 10% em qualquer serviço.',
        'Celebre o Ano Novo conosco! Mostre este SMS e receba 10% OFF em qualquer serviço.',
        'Presente de Ano Novo! Traga esta msg e ganhe 10% de desconto em todos os serviços.',
      ],
      emailSuggestions: [
        {
          title: 'Feliz Ano Novo! 🎉',
          body: 'Comece o novo ano com 10% OFF em todos os serviços ao mostrar este email. 🎇🥳',
        },
        {
          title: 'Celebre o Ano Novo conosco! 🌟',
          body: 'Mostre esta mensagem e ganhe 10% de desconto em qualquer serviço. 🎆🎁',
        },
        {
          title: 'Desconto especial! 🥂',
          body: 'Brinde ao novo ano com desconto especial! 🥂 Apresente este email e ganhe 10% em qualquer serviço. 🎇🎁',
        },
        {
          title: 'Celebre o Ano Novo conosco! 🍾',
          body: 'Mostre este email e receba 10% OFF em qualquer serviço. 🎊💇‍♂️',
        },
        {
          title: 'Presente de Ano Novo! 🎁',
          body: 'Traga esta mensagem e ganhe 10% de desconto em todos os serviços. 🎊',
        },
      ],
    },
  ];

  private templatesEs = [
    {
      "name": "Día de Reyes",
      "emoji": "👑",
      "monthDay": "01-06",
      "date": null,
      "smsSuggestions": [
        "¡Celebra el Día de Reyes con estilo! Disfruta de un 10% de descuento en todos nuestros servicios. ¡Reserva ahora!",
        "Regálate algo especial este Día de Reyes - 10% de descuento en nuestros servicios. ¡Reserva ya!",
        "¡Reyes y reinas merecen lo mejor! Disfruta hoy de un 10% de descuento en nuestros servicios.",
        "Haz mágico el Día de Reyes con un 10% de descuento en todos los servicios. ¡Reserva ya!",
        "Celebra el Día de Reyes con una oferta real - 10% de descuento en nuestros servicios. ¡Reserva ahora!"
      ],
      "emailSuggestions": [
        {
          "title": "¡Feliz Día de Reyes! 👑",
          "body": "Disfruta de un descuento real del 10% en todos nuestros servicios."
        },
        {
          "title": "¡Celebra como un Rey o Reina! 👑",
          "body": "Este Día de Reyes, disfruta del 10% de descuento en nuestros servicios."
        },
        {
          "title": "¡Ofertas dignas de la realeza! 👑",
          "body": "Especial Día de Reyes: ¡10% de descuento en todos los servicios!"
        },
        {
          "title": "¡Atención Reyes y Reinas! 👑",
          "body": "No te pierdas nuestra oferta especial de Día de Reyes - 10% de descuento."
        },
        {
          "title": "¡Un Día de Reyes inolvidable! 👑",
          "body": "Transforma este Día de Reyes con un 10% de descuento en todos nuestros servicios."
        }
      ]
    },    
    {
      "name": "Carnaval",
      "emoji": "🎉",
      "monthDay": "02-13",
      "date": null,
      "smsSuggestions": [
        "¡Carnaval es sinónimo de fiesta! Celebra con un 10% de descuento en nuestros servicios. ¡Reserva ahora!",
        "Alegría de Carnaval con descuentos especiales - 10% en todos los servicios. ¡Reserva ya!",
        "Este Carnaval, brilla más con un 10% de descuento en nuestros servicios. ¡Reserva ahora!",
        "Festeja el Carnaval a lo grande - disfruta de un 10% de descuento. ¡Reserva ya!",
        "Transforma tu Carnaval con nuestros servicios - ahora con un 10% de descuento. ¡Reserva ahora!"
      ],
      "emailSuggestions": [
        {
          "title": "¡Celebra el Carnaval a lo grande! 🎉",
          "body": "Disfruta de un 10% de descuento en todos nuestros servicios."
        },
        {
          "title": "¡Fiesta de Carnaval con descuento! 🎉",
          "body": "Oferta especial de Carnaval - 10% de descuento en nuestros servicios."
        },
        {
          "title": "¡Brilla este Carnaval! 🎉",
          "body": "Descuento especial de Carnaval: ¡10% en todos los servicios!"
        },
        {
          "title": "¡Carnaval con más color! 🎉",
          "body": "No te pierdas nuestra promoción de Carnaval - 10% de descuento."
        },
        {
          "title": "¡Alegría de Carnaval con descuento! 🎉",
          "body": "Celebra el Carnaval con un 10% de descuento en todos nuestros servicios."
        }
      ]
    },    
    {
      "name": "Día de San Valentín",
      "emoji": "💖",
      "monthDay": "02-14",
      "date": null,
      "smsSuggestions": [
        "¡El amor está en el aire! Celebra el Día de San Valentín con un 10% de descuento en nuestros servicios. ¡Reserva ahora!",
        "Sorprende a tu ser querido este Día de San Valentín con un regalo especial - 10% de descuento en nuestros servicios.",
        "Haz de este Día de San Valentín un momento inolvidable con un 10% de descuento en todos los servicios.",
        "Celebra el amor con una oferta especial - 10% de descuento en nuestros servicios este Día de San Valentín.",
        "El Día de San Valentín es para mimar a tu ser amado - disfruta de nuestro descuento del 10% en todos los servicios."
      ],
      "emailSuggestions": [
        {
          "title": "¡Celebra el amor! 💖 Día de San Valentín",
          "body": "Disfruta de nuestro descuento especial del 10% en todos los servicios."
        },
        {
          "title": "¡Amor y Sorpresas! 💖",
          "body": "Este Día de San Valentín, ofrecemos un 10% de descuento en nuestros servicios."
        },
        {
          "title": "¡Amor y Descuento! 💖",
          "body": "Transforma el Día de San Valentín con un 10% de descuento en todos los servicios."
        },
        {
          "title": "¡Oferta especial para los enamorados! 💖",
          "body": "10% de descuento en todos nuestros servicios este Día de San Valentín."
        },
        {
          "title": "¡Un Día de San Valentín inolvidable! 💖",
          "body": "Celebra con tu ser amado y disfruta de un 10% de descuento en nuestros servicios."
        }
      ]
    },    
    {
      "name": "Día del Padre",
      "emoji": "👔",
      "monthDay": "03-19",
      "date": null,
      "smsSuggestions": [
        "Celebra el Día del Padre con un regalo especial - 10% de descuento en todos nuestros servicios.",
        "En el Día del Padre, muestra cuánto significa él con un 10% de descuento en nuestros servicios.",
        "¡Los padres merecen lo mejor! Ofrece un 10% de descuento en todos los servicios este Día del Padre.",
        "Sorprende a tu padre con un regalo especial - disfruta de nuestro descuento del 10% en todos los servicios.",
        "Este Día del Padre, ofrece algo más que palabras - 10% de descuento en todos nuestros servicios."
      ],
      "emailSuggestions": [
        {
          "title": "¡Celebra el Día del Padre! 👔",
          "body": "Descuento especial del 10% en todos nuestros servicios."
        },
        {
          "title": "¡Un regalo para el mejor papá! 👔",
          "body": "Oferta especial de Día del Padre - 10% de descuento en nuestros servicios."
        },
        {
          "title": "¡Día del Padre con cariño! 👔",
          "body": "Muestra tu amor con un 10% de descuento en todos nuestros servicios."
        },
        {
          "title": "¡Sorprende a tu padre! 👔",
          "body": "Disfruta de nuestro descuento especial del 10% este Día del Padre."
        },
        {
          "title": "¡Los padres especiales merecen descuentos especiales! 👔",
          "body": "Ofrece un 10% de descuento en todos los servicios este Día del Padre."
        }
      ]
    },    
    {
      "name": "Primavera",
      "emoji": "🌸",
      "monthDay": "03-20",
      "date": null,
      "smsSuggestions": [
        "Celebra la llegada de la primavera con un 10% de descuento en nuestros servicios. ¡Reserva ahora!",
        "Da la bienvenida a la primavera con una oferta especial - 10% de descuento en nuestros servicios.",
        "¡La primavera es tiempo de renovación! Disfruta de un 10% de descuento en todos los servicios.",
        "Florece esta primavera con un 10% de descuento en todos nuestros servicios. ¡Reserva ya!",
        "La primavera ha llegado y trae descuentos. ¡10% de descuento en todos los servicios para ti!"
      ],
      "emailSuggestions": [
        {
          "title": "¡Bienvenida, Primavera! 🌸",
          "body": "Celebra la nueva estación con un 10% de descuento en todos nuestros servicios."
        },
        {
          "title": "¡Primavera en flor! 🌸",
          "body": "Descuento especial de primavera - 10% en todos los servicios."
        },
        {
          "title": "¡Renueva en esta Primavera! 🌸",
          "body": "Disfruta de la primavera con un 10% de descuento en todos nuestros servicios."
        },
        {
          "title": "¡Florece con descuentos! 🌸",
          "body": "La primavera ha llegado con un descuento especial del 10%."
        },
        {
          "title": "¡Celebra la Primavera! 🌸",
          "body": "Disfruta de un 10% de descuento en todos nuestros servicios esta primavera."
        }
      ]
    },             
    {
      "name": "Día de los Inocentes",
      "emoji": "🤥",
      "monthDay": "04-01",
      "date": null,
      "smsSuggestions": [
        "¡No es broma! Solo hoy, ofrecemos un 10% de descuento en nuestros servicios. ¡Reserva ahora!",
        "No te creas todo lo que escuchas - ¡excepto esta oferta! 10% de descuento en nuestros servicios. ¡Reserva ya!",
        "Esta promoción es tan buena que parece mentira, ¡pero no lo es! 10% de descuento en todos los servicios. ¡Reserva ya!",
        "¿Mentira? ¿Verdad? ¡Quién sabe! Reserva uno de nuestros servicios hoy con un 10% de descuento. ¡Reserva ahora!",
        "¡No, no es una mentira! ofrecemos un 10% de descuento en todos los servicios. ¡Reserva ya!"
      ],
      "emailSuggestions": [
        {
          "title": "¡No estamos bromeando! 🤥",
          "body": "Solo hoy, ofrecemos un 10% de descuento en nuestros servicios."
        },
        {
          "title": "No te creas todo lo que oigas...",
          "body": "No te creas todo lo que escuchas - ¡excepto esta oferta! 🤥 10% de descuento en nuestros servicios."
        },
        {
          "title": "Parece mentira 🤥",
          "body": "Esta promoción es tan buena que parece mentira, ¡pero no lo es! 🤥 10% de descuento en todos los servicios."
        },
        {
          "title": "¿Mentira? ¿Verdad? ¡Quién sabe! 🤥",
          "body": "Reserva uno de nuestros servicios hoy con un 10% de descuento."
        },
        {
          "title": "¿Una mentira??? 🤥",
          "body": "¡No, no es una mentira! Ofrecemos un 10% de descuento en todos los servicios."
        }
      ]
    },    
    {
      "name": "Semana Santa",
      "emoji": "🐣",
      "monthDay": "03-31",
      "date": null,
      "smsSuggestions": [
        "¡Feliz Semana Santa! Disfruta de un 10% de descuento en nuestros servicios. ¡Reserva ya!",
        "Esta Semana Santa, date un capricho con el mejor - disfruta de un 10% de descuento en nuestros servicios. ¡Reserva ya!",
        "¡Para celebrar la Semana Santa, estamos ofreciendo un 10% de descuento en todos los servicios! ¡Reserva ya!",
        "¡No te pierdas nuestra promoción especial de Semana Santa - 10% de descuento en nuestros servicios! ¡Reserva ya!",
        "Celebra la Semana Santa con un regalo especial. ¡Disfruta de un 10% de descuento en todos los servicios! ¡Reserva ya!"
      ],
      "emailSuggestions": [
        {
          "title": "¡Feliz Semana Santa! 🐣",
          "body": "Disfruta de un 10% de descuento en nuestros servicios."
        },
        {
          "title": "¡Ha llegado la Semana Santa! 🐣",
          "body": "Esta Semana Santa, date un capricho con el mejor - disfruta de un 10% de descuento en nuestros servicios."
        },
        {
          "title": "¡Vamos a celebrar la Semana Santa! 🐣",
          "body": "Para celebrar la Semana Santa, estamos ofreciendo un 10% de descuento en todos los servicios."
        },
        {
          "title": "¡10% de descuento! 🐣",
          "body": "¡No te pierdas nuestra promoción especial de Semana Santa - 10% de descuento en nuestros servicios."
        },
        {
          "title": "¡Un regalo especial! 🐣",
          "body": "Celebra la Semana Santa con un regalo especial. ¡Disfruta de un 10% de descuento en todos los servicios!"
        }
      ]
    },    
    {
      "name": "Día de la Madre",
      "emoji": "👩‍🍼",
      "monthDay": "05-05",
      "date": null,
      "smsSuggestions": [
        "¡Feliz Día de la Madre! Disfruta de un 10% de descuento en nuestros servicios. ¡Reserva ahora!",
        "El amor de una madre es eterno, ¡y nuestro amor por ellas también! Ahora un 10% de descuento en todos los servicios. ¡Reserva ya!",
        "Para celebrar el Día de la Madre, ¡estamos ofreciendo un 10% de descuento en todos los servicios! ¡Reserva ya!",
        "Promoción especial del Día de la Madre - ¡10% de descuento en nuestros servicios! ¡Reserva ya!",
        "Celebra el Día de la Madre con un regalo especial. ¡Disfruta de un 10% de descuento en todos los servicios! ¡Reserva ya!"
      ],
      "emailSuggestions": [
        {
          "title": "¡Feliz Día de la Madre! 👩‍🍼",
          "body": "Disfruta de un 10% de descuento en nuestros servicios."
        },
        {
          "title": "El amor de una madre es eterno 👩‍🍼",
          "body": "El amor de una madre es eterno, ¡y nuestro amor por ellas también! 👩‍🍼 Ahora un 10% de descuento en todos los servicios."
        },
        {
          "title": "¡Vamos a celebrar el Día de la Madre! 👩‍🍼",
          "body": "Para celebrar el Día de la Madre, ¡estamos ofreciendo un 10% de descuento en todos los servicios!"
        },
        {
          "title": "¡10% en todos los servicios",
          "body": "Promoción especial del Día de la Madre - ¡disfruta ya de un 10% de descuento en nuestros servicios! 👩‍🍼"
        },
        {
          "title": "Celebra el Día de la Madre con un regalo especial. 👩‍🍼",
          "body": "Celebra el Día de la Madre con un regalo especial. 👩‍🍼 ¡Disfruta de un 10% de descuento en todos los servicios!"
        }
      ]
    },    
    {
      "name": "Verano",
      "emoji": "🌞",
      "monthDay": "07-21",
      "date": null,
      "smsSuggestions": [
        "¡Ha llegado el verano! Prepárate para la temporada más caliente y animada del año. ¡Programa un servicio con nosotros ahora y obtén un 10% de descuento!",
        "Mantente fresco este verano con nuestra promoción especial. ¡Reserva un servicio a tu elección y disfruta de un 10% de descuento!",
        "La mejor época del año ya está aquí: ¡Verano! Elige un servicio a tu elección y obtén un descuento inmediato del 10%.",
        "Aprovecha el sol y el calor para un cambio de look con un 10% de descuento en todos los servicios de tu elección. ¡Reserva ya!",
        "¡Ve de vacaciones con un nuevo look! Disfruta de un 10% de descuento en todos nuestros servicios. ¡Reserva ya!"
      ],
      "emailSuggestions": [
        {
          "title": "¡Ha llegado el verano! 🌞",
          "body": "Prepárate para la temporada más caliente y animada del año. ¡Programa un servicio con nosotros ahora y obtén un 10% de descuento!"
        },
        {
          "title": "¡Mantente fresco con nuestra promoción!",
          "body": "Mantente fresco este verano con nuestra promoción especial. ¡Reserva un servicio a tu elección y disfruta de un 10% de descuento!"
        },
        {
          "title": "La mejor época del año ha llegado ⛱",
          "body": "La mejor época del año es el verano! ⛱ Elige un servicio a tu elección y obtén un descuento inmediato del 10%."
        },
        {
          "title": "¡Ha llegado el sol y el calor! 🏖",
          "body": "Aprovecha el sol y el calor para un cambio de look con un 10% de descuento en todos los servicios de tu elección. 🏖 ¡Reserva ya!"
        },
        {
          "title": "¡Ve de vacaciones con un nuevo look!",
          "body": "👙 Disfruta de un 10% de descuento en todos nuestros servicios. ¡Reserva ya!"
        }
      ]
    },    
    {
      "name": "Vuelta al Cole",
      "emoji": "📚",
      "monthDay": "09-20",
      "date": null,
      "smsSuggestions": [
        "¡Especial Vuelta al Cole! Disfruta de un 10% de descuento en nuestros servicios. ¡Reserva ahora!",
        "¡Empieza el año escolar con un nuevo look! Disfruta de un 10% de descuento en nuestros servicios. ¡Reserva ya!",
        "¡Nuevo año escolar, nuevo tú! Consigue un 10% de descuento en todos nuestros servicios. ¡Reserva ahora!",
        "¡Da la bienvenida al nuevo año académico con un 10% de descuento al reservar un servicio con nosotros hoy!",
        "Prepárate para el nuevo trimestre con un 10% de descuento en todos nuestros servicios. ¡Haz tu cita hoy!"
      ],
      "emailSuggestions": [
        {
          "title": "¡Especial Vuelta al Cole! 📚",
          "body": "Disfruta de un 10% de descuento en nuestros servicios. ¡Reserva ahora!"
        },
        {
          "title": "¡Empieza el Año Escolar con un Nuevo Look! 🎒",
          "body": "Disfruta de un 10% de descuento en nuestros servicios. ¡Reserva ahora!"
        },
        {
          "title": "¡Nuevo Año Escolar, Nuevo Tú! 🏫",
          "body": "Consigue un 10% de descuento en todos nuestros servicios. ¡Reserva ahora!"
        },
        {
          "title": "¡Bienvenido el Nuevo Año Académico con una Explosión! 💥",
          "body": "Consigue un 10% de descuento al reservar un servicio con nosotros hoy."
        },
        {
          "title": "¡Prepárate para el Nuevo Trimestre con un Descuento! ✏️",
          "body": "Disfruta de un 10% de descuento en todos nuestros servicios. ¡Haz tu cita hoy!"
        }
      ]
    },    
    {
      "name": "Otoño",
      "emoji": "🍂",
      "monthDay": "09-23",
      "date": null,
      "smsSuggestions": [
        "¡Ha llegado el otoño! Prepárate para esta temporada y programa un servicio con un 10% de descuento. ¡Reserva ya!",
        "¡Disfruta del otoño con nosotros! Obtén un 10% de descuento en tu próxima reserva. Válido hasta fin de temporada. ¡Esperamos verte pronto!",
        "Mantente a la moda con las tendencias de otoño y aprovecha nuestra promoción especial. ¡Reserva un servicio y disfruta de un 10% de descuento!",
        "El otoño es tiempo de renovación. Por ello, ofrecemos un 10% de descuento en todos nuestros servicios. ¡Reserva ya!",
        "Aprovecha el otoño para un cambio de look con un 10% de descuento en todos los servicios de tu elección. ¡Reserva ya!"
      ],
      "emailSuggestions": [
        {
          "title": "¡Ha llegado el otoño! 🍂",
          "body": "Prepárate para esta temporada y programa un servicio con un 10% de descuento. ¡Reserva ya!"
        },
        {
          "title": "¡Disfruta del otoño con nosotros! 🍂",
          "body": "Obtén un 10% de descuento en tu próxima reserva. Válido hasta fin de temporada. ¡Esperamos verte pronto!"
        },
        {
          "title": "Promoción de Otoño 🍁",
          "body": "Mantente a la moda con las tendencias de otoño y aprovecha nuestra promoción especial. 🍁 ¡Reserva un servicio y disfruta de un 10% de descuento!"
        },
        {
          "title": "Mímate este Otoño 🍃",
          "body": "El otoño es tiempo de renovación 🍃 Por ello, ofrecemos un 10% de descuento en todos nuestros servicios. ¡Reserva ya!"
        },
        {
          "title": "Cambia tu Look este Otoño 🍁",
          "body": "Aprovecha el otoño para un cambio de look con un 10% de descuento en todos los servicios de tu elección. 🍁 ¡Reserva ya!"
        }
      ]
    },    
    {
      "name": "Halloween",
      "emoji": "👻",
      "monthDay": "10-31",
      "date": null,
      "smsSuggestions": [
        "¡Muestra este texto y obtén un 10% de descuento en cualquiera de nuestros servicios! ¡Disfruta de nuestra promoción de Halloween!",
        "¡Sorpresa espeluznante! Presenta este texto y recibe un 10% de descuento en todos los servicios ofrecidos.",
        "¡Celebra Halloween con nosotros! Muestra este mensaje y obtén un 10% de descuento en cualquier servicio.",
        "¡La fiesta comienza aquí! Trae este mensaje y consigue un 10% de descuento en cualquier servicio. ¡Feliz Halloween!",
        "¡Celebra Halloween con nosotros! Muestra este texto y obtén un 10% de descuento en cualquier servicio."
      ],
      "emailSuggestions": [
        {
          "title": "¡Disfruta de nuestra promoción de Halloween! 👻",
          "body": "Muestra este texto y obtén un 10% de descuento en cualquiera de nuestros servicios. 🎃"
        },
        {
          "title": "👻 ¡Sorpresa espeluznante!",
          "body": "Presenta este texto y recibe un 10% de descuento en todos los servicios ofrecidos. 🎭🕯️"
        },
        {
          "title": "¡Celebra Halloween con nosotros! 🌙",
          "body": "Muestra este mensaje y obtén un 10% de descuento en cualquier servicio. 🎃🍭"
        },
        {
          "title": "¡Feliz Halloween! 🎃👻",
          "body": "🎉 La fiesta comienza aquí! Trae este mensaje y consigue un 10% de descuento en cualquier servicio."
        },
        {
          "title": "¡Celebra Halloween con nosotros! 🌟",
          "body": "Muestra este texto y obtén un 10% de descuento en cualquier servicio. 🕷️🍁"
        }
      ]
    },    
    {
      "name": "Black Friday",
      "emoji": "🤑",
      "monthDay": "11-29",
      "date": null,
      "smsSuggestions": [
        "¡Black Friday ha llegado! Muestra este texto y obtén un 10% DE DESCUENTO en cualquier servicio en nuestra tienda.",
        "¡Oferta exclusiva de Black Friday! Trae este texto y consigue un 10% DE DESCUENTO en todos los servicios. ¡No te lo pierdas!",
        "Muestra este texto para desbloquear tu descuento del 10% en cualquier servicio. ¡Feliz Black Friday!",
        "¡Oferta imperdible de Black Friday! Obtén un 10% DE DESCUENTO en cualquier procedimiento al mostrar este mensaje. ¡Disfruta!",
        "¡Celebra el Black Friday con nosotros! Muestra este texto y consigue un 10% DE DESCUENTO en cualquier servicio."
      ],
      "emailSuggestions": [
        {
          "title": "¡Black Friday ha llegado! 🛍️",
          "body": "Muestra este email y obtén un 10% DE DESCUENTO en cualquier servicio en nuestra tienda. 🎉💰"
        },
        {
          "title": "¡Oferta exclusiva de Black Friday! 🎉",
          "body": "Trae este email y consigue un 10% DE DESCUENTO en todos los servicios. ¡No te lo pierdas! 💆‍♀️💆‍♂️"
        },
        {
          "title": "¡Feliz Black Friday! 🎊💇‍♂️",
          "body": "Muestra este email para desbloquear tu descuento del 10% 🏷️ en cualquier servicio."
        },
        {
          "title": "¡Oferta imperdible de Black Friday! 🚀",
          "body": "Obtén un 10% DE DESCUENTO en cualquier procedimiento al presentar este email. ¡Disfruta! 🎁💆‍♀️"
        },
        {
          "title": "¡Celebra el Black Friday con nosotros! 🎊",
          "body": "Muestra este email y consigue un 10% DE DESCUENTO en cualquier servicio."
        }
      ]
    },    
    {
      "name": "Cyber Monday",
      "emoji": "💻",
      "monthDay": "12-02",
      "date": null,
      "smsSuggestions": [
        "¡Cyber Monday ya está aquí! Muestra este texto y obtén un 10% DE DESCUENTO en cualquier servicio en nuestra tienda.",
        "¡Oferta exclusiva de Cyber Monday! Trae este texto y consigue un 10% DE DESCUENTO en todos los servicios. ¡No te lo pierdas!",
        "Muestra este texto para desbloquear tu descuento del 10% en cualquier servicio. ¡Feliz Cyber Monday!",
        "¡Oferta imperdible de Cyber Monday! Obtén un 10% DE DESCUENTO en cualquier procedimiento al presentar este texto. ¡Disfruta!",
        "¡Celebra Cyber Monday con nosotros! Muestra este texto y consigue un 10% DE DESCUENTO en cualquier servicio."
      ],
      "emailSuggestions": [
        {
          "title": "¡Cyber Monday ya está aquí! 🛍️",
          "body": "Muestra este email y obtén un 10% DE DESCUENTO en cualquier servicio en nuestra tienda. 🎉💰"
        },
        {
          "title": "¡Oferta exclusiva de Cyber Monday! 🎉",
          "body": "Trae este email y consigue un 10% DE DESCUENTO en todos los servicios. ¡No te lo pierdas! 💆‍♀️💆‍♂️"
        },
        {
          "title": "¡Feliz Cyber Monday! 🤑💻",
          "body": "Muestra este email para desbloquear tu descuento del 10% 🏷️ en cualquier servicio."
        },
        {
          "title": "¡Oferta imperdible de Cyber Monday! 🚀",
          "body": "Obtén un 10% DE DESCUENTO en cualquier procedimiento al presentar este email. ¡Disfruta! 🤑"
        },
        {
          "title": "¡Celebra Cyber Monday con nosotros! 🎊",
          "body": "Muestra este email y consigue un 10% DE DESCUENTO en cualquier servicio."
        }
      ]
    },    
    {
      "name": "Invierno",
      "emoji": "❄️",
      "monthDay": "12-23",
      "date": null,
      "smsSuggestions": [
        "Muestra este texto y calienta tu invierno con un 10% DE DESCUENTO en cualquier servicio. ¡Disfruta de nuestra promoción!",
        "¡Promoción de invierno! Trae este texto y consigue un 10% de descuento en cualquier servicio.",
        "¡Descuento calentito! Muestra este texto y obtén un 10% de descuento en cualquier servicio durante este invierno.",
        "¡Disfruta del invierno con nosotros! Muestra este texto y obtén un 10% de descuento en cualquier servicio. ¡Calienta el ambiente!",
        "¡Calienta con nuestra promoción de invierno! Muestra este texto y obtén un 10% de descuento en todos los servicios."
      ],
      "emailSuggestions": [
        {
          "title": "¡Disfruta de nuestra promoción! 🔥",
          "body": "Muestra este texto y calienta tu invierno ❄️ con un 10% DE DESCUENTO en cualquier servicio."
        },
        {
          "title": "¡Promoción de invierno! 🌨️",
          "body": "Trae este texto y consigue un 10% de descuento en cualquier servicio. ❄️"
        },
        {
          "title": "¡Descuento calentito! 🔥",
          "body": "Muestra este texto y obtén un 10% de descuento en cualquier servicio durante este invierno. 🛍️"
        },
        {
          "title": "¡Disfruta del invierno con nosotros! ❄️",
          "body": "Muestra este texto y obtén un 10% de descuento en cualquier servicio. ¡Calienta el ambiente! 🔥🏂"
        },
        {
          "title": "¡Calienta con nuestra promoción de invierno! 🧥",
          "body": "Muestra este texto y obtén un 10% de descuento en todos los servicios. 🔥"
        }
      ]
    },    
    {
      "name": "Navidad",
      "emoji": "🎄",
      "monthDay": "12-25",
      "date": null,
      "smsSuggestions": [
        "¡Feliz Navidad! Disfruta de nuestro regalo: 10% DE DESCUENTO en cualquier servicio al mostrar este texto.",
        "¡Regálate algo esta Navidad! Presenta este texto y ahorra un 10% en cualquier servicio.",
        "¡Navidad con descuento! Trae este mensaje y obtén un 10% DE DESCUENTO en todos los servicios. ¡Disfruta de la temporada!",
        "¡La magia de la Navidad ha llegado! Consigue un 10% de descuento al mostrar este texto. ¡Disfruta de nuestros servicios!",
        "¡Celebra la Navidad con nosotros! Muestra este texto y recibe un 10% DE DESCUENTO en cualquier servicio."
      ],
      "emailSuggestions": [
        {
          "title": "¡Feliz Navidad! 🎄",
          "body": "Disfruta de nuestro regalo: 10% DE DESCUENTO en cualquier servicio al mostrar este email. 🎁🎅"
        },
        {
          "title": "¡Regálate algo esta Navidad! 🎁",
          "body": "Presenta este email y ahorra un 10% en cualquier servicio. 🎅💆‍♀️"
        },
        {
          "title": "¡Navidad con descuento! 🎄",
          "body": "Trae este mensaje y obtén un 10% DE DESCUENTO en todos los servicios. ¡Disfruta de la temporada! 🎅"
        },
        {
          "title": "¡La magia de la Navidad ha llegado! 🌟",
          "body": "Consigue un 10% de descuento al mostrar este email. ¡Disfruta de nuestros servicios! 🎅💆‍♂️"
        },
        {
          "title": "¡Celebra la Navidad con nosotros! ⛄",
          "body": "Muestra este email y recibe un 10% DE DESCUENTO en cualquier servicio. 🎄🎉"
        }
      ]
    },    
    {
      "name": "Nochevieja",
      "emoji": "🥂",
      "monthDay": "01-01",
      "date": null,
      "smsSuggestions": [
        "¡Feliz Año Nuevo! Empieza el nuevo año con un 10% DE DESCUENTO en todos los servicios al mostrar este texto.",
        "¡Celebra el Año Nuevo con nosotros! Muestra este texto y obtén un 10% de descuento en cualquier servicio.",
        "¡Brinda por el nuevo año con un descuento especial! Presenta este texto y disfruta de un 10% de descuento en cualquier servicio.",
        "¡Celebra el Año Nuevo con nosotros! Muestra este texto y recibe un 10% DE DESCUENTO en cualquier servicio.",
        "¡Regalo de Año Nuevo! Trae este texto y consigue un 10% de descuento en todos los servicios."
      ],
      "emailSuggestions": [
        {
          "title": "¡Feliz Año Nuevo! 🎉",
          "body": "Empieza el nuevo año con un 10% DE DESCUENTO en todos los servicios al mostrar este texto. 🎇🥳"
        },
        {
          "title": "¡Celebra el Año Nuevo con nosotros! 🌟",
          "body": "Muestra este texto y obtén un 10% de descuento en cualquier servicio. 🎆🎁"
        },
        {
          "title": "¡Descuento especial! 🥂",
          "body": "Brinda por el nuevo año con un descuento especial. 🥂 Presenta este texto y obtén un 10% de descuento en cualquier servicio. 🎇🎁"
        },
        {
          "title": "¡Celebra el Año Nuevo con nosotros! 🍾",
          "body": "Muestra este texto y recibe un 10% DE DESCUENTO en cualquier servicio. 🎊💇‍♂️"
        },
        {
          "title": "¡Regalo de Año Nuevo! 🎁",
          "body": "Trae este texto y consigue un 10% de descuento en todos los servicios. 🎇🎊"
        },
      ],
    },
  ];
  
  private templates = [];

  getTemplates() {
    if (this.language === 'pt') {
      this.templates = this.templatesPt;
    } else if (this.language === 'en') {
      this.templates = this.templatesEn;
    } else if (this.language === 'es') {
      this.templates = this.templatesEs;
    }

    // get the next 3 templates taking into account the date

    // go to all templates and add the date
    const templates = this.templates.map((template) => {
      let date = parse(template.monthDay, 'MM-dd', new Date());

      if (isBefore(endOfDay(date), new Date())) {
        date = addYears(date, 1);
      }

      template.date = format(date, 'yyyy-MM-dd');
      return template;
    });

    // sort by date
    templates.sort((a, b) => {
      return new Date(a.date).getTime() - new Date(b.date).getTime();
    });

    return templates.slice(0, 3);
  }
}
