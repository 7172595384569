import { ILandingConfig } from '../../core/services/landing.service';

export const generalLandingConfig: ILandingConfig = {
  pageTitle: 'trans:LANDING.landing.metaTitle',
  pageDescription: 'trans:LANDING.landing.pageDescription',
  pageOgImage: {pt: 'https://buk.app/assets/ogimage.png', en: 'https://buk.app/assets/ogimage_en.png', es: 'https://buk.app/assets/ogimage_es.png'},
  buisiness: 'trans:LANDING.landing.businesses.barber',
  tagline: 'trans:LANDING.landing.tagline',
  heroImg: 'hero-geral.png',
  appImg: 'app-geral.png',
  login: 'trans:LANDING.landing.login',
  registerCta: 'trans:LANDING.landing.registerCta',
  contactCta: 'trans:LANDING.landing.contactCta',
  promoCode: 'TRYPRO',
  headerDescription: 'trans:LANDING.landing.headerDescription',
  featuresTitle: 'trans:LANDING.landing.featuresTitle',
  features: [
    {
      icon: 'ico-calendar',
      title: 'trans:LANDING.landing.features.calendarTitle',
      text: 'trans:LANDING.landing.features.calendarText',
    },
    {
      icon: 'ico-booking-page',
      title: 'trans:LANDING.landing.features.bookingTitle',
      text: 'trans:LANDING.landing.features.bookingText',
    },
    {
      icon: 'ico-team',
      title: 'trans:LANDING.landing.features.teamTitle',
      text: 'trans:LANDING.landing.features.teamText',
    },
    {
      icon: 'ico-sms',
      title: 'trans:LANDING.landing.features.smsTitle',
      text: 'trans:LANDING.landing.features.smsText',
    },
    {
      icon: 'ico-stats',
      title: 'trans:LANDING.landing.features.statsTitle',
      text: 'trans:LANDING.landing.features.statsText',
    },
    {
      icon: 'ico-bookings',
      title: 'trans:LANDING.landing.features.bookingsTitle',
      text: 'trans:LANDING.landing.features.bookingsText',
    },
    {
      icon: 'ico-marketing2',
      title: 'trans:LANDING.landing.features.marketing2Title',
      text: 'trans:LANDING.landing.features.marketing2Text',
    },
    {
      icon: 'ico-mbway2',
      title: 'trans:LANDING.landing.features.mbway2Title',
      text: 'trans:LANDING.landing.features.mbway2Text',
    },
    {
      icon: 'ico-google2',
      title: 'trans:LANDING.landing.features.google2Title',
      text: 'trans:LANDING.landing.features.google2Text',
    },
  ],
  testimoniesTitle: 'trans:LANDING.landing.testimoniesTitle',
  testemonies: [
    {
      text: 'trans:LANDING.landing.testemonies.testemonyBento',
      image: 'assets/av-david.jpg',
      person: 'David Frazão',
      buisiness: 'Bento Barbearias',
    },
    {
      text: 'trans:LANDING.landing.testemonies.testemonyAnton',
      image: 'assets/av-anton.jpg',
      person: 'Anton Beill',
      buisiness: 'Anton Beill Haircare',
    },
    // {
    //   text: 'trans:LANDING.landing.testemonies.testemonyLigia',
    //   image: 'assets/av-ligia.jpg',
    //   person: 'Lígia Caseirito',
    //   buisiness: 'Nail Me Perfect',
    // },
  ],
  businessesTitle: 'trans:LANDING.landing.businessesTitle',
  businesses: [
    {
      class: 'bg-barber',
      text: 'trans:LANDING.landing.businesses.barber',
      urls: { pt: '/barbeiros', en: '/barbers', es: '/barbers' },
    },
    {
      class: 'bg-salon',
      text: 'trans:LANDING.landing.businesses.salons',
      urls: { pt: '/cabeleireiros', en: '/hairdressers', es: '/hairdressers' },
    },
    {
      class: 'bg-nails',
      text: 'trans:LANDING.landing.businesses.nailss',
      urls: { pt: '/nail-artists', en: '/nail-artists', es: '/nail-artists' },
    },
    {
      class: 'bg-makeup',
      text: 'trans:LANDING.landing.businesses.makeups',
      urls: { pt: '/makeup-artists', en: '/makeup-artists', es: '/makeup-artists' },
    },
    {
      class: 'bg-fitness',
      text: 'trans:LANDING.landing.businesses.fitness',
      urls: { pt: '/personal-trainers', en: '/personal-trainers', es: '/personal-trainers' },
    },
    {
      class: 'bg-nutri',
      text: 'trans:LANDING.landing.businesses.nutri',
      urls: { pt: '/nutricionistas', en: '/nutritionists', es: '/nutritionists' },
    },
    {
      class: 'bg-fisio',
      text: 'trans:LANDING.landing.businesses.fisio',
      urls: { pt: '/fisioterapeutas', en: '/physiotherapists', es: '/physiotherapists' },
    },
    {
      class: 'bg-osteo',
      text: 'trans:LANDING.landing.businesses.osteo',
      urls: { pt: '/osteopatas', en: '/osteopaths', es: '/osteopaths' },
    },
    {
      class: 'bg-dentist',
      text: 'trans:LANDING.landing.businesses.dentists',
      urls: { pt: '/dentistas', en: '/dentists', es: '/dentists' },
    },
    {
      class: 'bg-psi',
      text: 'trans:LANDING.landing.businesses.psys',
      urls: { pt: '/psicologos', en: '/psychologists', es: '/psychologists' },
    },
    {
      class: 'bg-pharma',
      text: 'trans:LANDING.landing.businesses.pharmacies',
      urls: { pt: '/farmacias', en: '/pharmacies', es: '/pharmacies' },
    },
    {
      class: 'bg-others',
      text: 'trans:LANDING.landing.businesses.others',
      urls: { pt: '/signup', en: '/signup', es: '/signup' },
    },
  ],
  businessesFooter: [
    {
      title: 'trans:LANDING.landing.businesses.beauty',
      links: [
        {
          urls: { pt: '/barbeiros', en: '/barbers', es: '/barbers' },
          text: 'trans:LANDING.landing.businesses.barbers',
        },
        {
          urls: { pt: '/cabeleireiros', en: '/hairdressers', es: '/hairdressers' },
          text: 'trans:LANDING.landing.businesses.salons',
        },
        {
          urls: { pt: '/nail-artists', en: '/nail-artists', es: '/nail-artists' },
          text: 'trans:LANDING.landing.businesses.nailss',
        },
        {
          urls: { pt: '/makeup-artists', en: '/makeup-artists', es: '/makeup-artists' },
          text: 'trans:LANDING.landing.businesses.makeups',
        },
      ],
    },
    {
      title: 'trans:LANDING.landing.businesses.sports',
      links: [
        {
          urls: { pt: '/ginasios', en: '/gyms', es: '/gyms' },
          text: 'trans:LANDING.landing.businesses.gyms',
        },
        {
          urls: { pt: '/personal-trainers', en: '/personal-trainers', es: '/personal-trainers' },
          text: 'trans:LANDING.landing.businesses.pts',
        },
      ],
    },
    {
      title: 'trans:LANDING.landing.businesses.health',
      links: [
        {
          urls: { pt: '/fisioterapeutas', en: '/physiotherapists', es: '/physiotherapists' },
          text: 'trans:LANDING.landing.businesses.fisio',
        },
        {
          urls: { pt: '/psicologos', en: '/psychologists', es: '/psychologists' },
          text: 'trans:LANDING.landing.businesses.psys',
        },
        {
          urls: { pt: '/nutricionistas', en: '/nutritionists', es: '/nutritionists' },
          text: 'trans:LANDING.landing.businesses.nutricionists',
        },
        {
          urls: { pt: '/dentistas', en: '/dentists', es: '/dentists' },
          text: 'trans:LANDING.landing.businesses.dentists',
        },
        {
          urls: { pt: '/farmacias', en: '/pharmacies', es: '/pharmacies' },
          text: 'trans:LANDING.landing.businesses.pharmacies',
        },
        {
          urls: { pt: '/centros-de-terapias', en: '/therapy-center', es: '/therapy-center' },
          text: 'trans:LANDING.landing.businesses.therapy',
        },
      ],
    },
    {
      title: 'trans:LANDING.landing.businesses.alternativeTherapies',
      links: [
        {
          urls: { pt: '/osteopatas', en: '/osteopaths', es: '/osteopaths' },
          text: 'trans:LANDING.landing.businesses.osteopathy',
        },
        {
          urls: { pt: '/homeopatia', en: '/homeopathy', es: '/homeopathy' },
          text: 'trans:LANDING.landing.businesses.homeopathy',
        },
        {
          urls: { pt: '/reiki', en: '/reiki', es: '/reiki' },
          text: 'trans:LANDING.landing.businesses.reiki',
        },
        {
          urls: { pt: '/acupuntura', en: '/acupuncture', es: '/acupuncture' },
          text: 'trans:LANDING.landing.businesses.acupuncture',
        },
      ],
    },
  ],
};
