import { ILandingConfig } from '../../core/services/landing.service';
import { beautyLandingConfig } from './1-beautyConfig';

const configOverride: ILandingConfig = {
  pageTitle: 'trans:LANDING.landing.taglinePsy',
  tagline: 'trans:LANDING.landing.taglinePsy',
  heroImg: 'hero-psy.png',
  appImg: 'app-psy.png',
  buisiness: 'trans:LANDING.landing.businesses.psys',
  featuresTitle: 'trans:LANDING.landing.featuresTitlePsy',
};

export const psychologistsLandingConfig: ILandingConfig = {
  ...beautyLandingConfig,
  ...configOverride,
};
