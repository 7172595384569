import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { IntlService } from '../services/intl.service';
import { environment } from '../../../environments/environment';


export const SetLanguageGuard: CanActivateFn = (route, state) => {
  const intlService: IntlService = inject(IntlService);
  const router: Router = inject(Router);

  const availableLanguages = intlService.getAvailableLanguageCodes();
  const currentHost = intlService.getHost();
  const currentHostDefaults = environment.domains.find(l => l.host === currentHost);

  let routerSlug = route.params.slug;

  const subdomainLanguage = route.data.state === 'subdomainLanguage';
  if (subdomainLanguage) {
    routerSlug = state.url.replace('/', '');
  }

  if (availableLanguages.includes(routerSlug)) {
    // url has language slug
    if (currentHostDefaults?.defaultCountry === 'us') {
      intlService.setCurrentLanguage(routerSlug);
      intlService.setCurrentCountry(routerSlug === 'en' ? 'us' : routerSlug);
    }

    const url = state.url.replace(`/${routerSlug}`, '');

    if (url || (currentHostDefaults?.defaultCountry !== 'us' && subdomainLanguage)) {
      // navigate to url without language slug if url has more than language slug
      // OR if current host is not en and route is subdomainLanguage
      router.navigateByUrl(url);
      return false;
    }
  }

  return true;
};

