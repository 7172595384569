import { ILandingConfig } from '../../core/services/landing.service';
import { sportsLandingConfig } from './2-sportsConfig';

const configOverride: ILandingConfig = {
  pageTitle: 'trans:LANDING.landing.taglineGym',
  tagline: 'trans:LANDING.landing.taglineGym',
  heroImg: 'hero-geral.png',
  appImg: 'app-geral.png',
  buisiness: 'trans:LANDING.landing.businesses.gyms',
  featuresTitle: 'trans:LANDING.landing.featuresTitleGym',
};

export const gymsLandingConfig: ILandingConfig = {
  ...sportsLandingConfig,
  ...configOverride,
  testemonies: [
    {
      text: 'Com a utilização do BUK passámos a ter um controlo efetivo de todas as marcações dos nossos clientes tornando assim a frequência dos nossos espaços de treino muito mais agradável e segura!',
      image: 'assets/av-hugo-moniz.jpg',
      person: 'Hugo Moniz (CEO)',
      buisiness: 'Exercise Studio Lisboa',
    },
    {
      text: 'O BUK é exactamente o que precisava: agendamento online, e-mails e sms automáticos, super eficaz, clientes e eu super satisfeitos!',
      image: 'assets/av-joao-cunha.jpg',
      person: 'João Cunha',
      buisiness: 'Personal Training Solutions',
    },
    // {
    //   text: 'Com a plataforma de marcações da BUK, é mais simples não só a marcação dos treinos por parte dos alunos, como toda a gestão e controlo dos nossos serviços. Simples, Prático e Eficiente!',
    //   image: 'assets/av-nuno-mendes.jpg',
    //   person: 'Nuno Mendes',
    //   buisiness: 'New Me',
    // },
  ],
};
