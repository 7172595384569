import { TranslateLoader } from '@ngx-translate/core';

import { Observable, of } from 'rxjs';

import * as translationPt from '../../assets/i18n-lang/pt.json';
import * as translationEn from '../../assets/i18n-lang/en.json';
import * as translationEs from '../../assets/i18n-lang/es.json';

const TRANSLATIONS = {
  en: translationEn,
  pt: translationPt,
  es: translationEs,
};

import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';

export class TranslateServerService implements TranslateLoader {

  constructor(private transferState: TransferState) { }
  /**
  * Gets the translations from the server
  * @param lang
  * @returns {any}
  */
  public getTranslation(lang: string): Observable<any> {
    const key: StateKey<number> = makeStateKey<number>('transfer-translate-' + lang);
    this.transferState.set(key, TRANSLATIONS[lang]);
    return of(TRANSLATIONS[lang]);
  }
}
