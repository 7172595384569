import { RouterModule } from '@angular/router';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler, PLATFORM_ID } from '@angular/core';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';

import { registerLocaleData } from '@angular/common';
import localePtpt from '@angular/common/locales/pt-PT';
import localeEs from '@angular/common/locales/es';
import { defineLocale, enGbLocale } from 'ngx-bootstrap/chronos';
import { ptBrLocale, esLocale } from 'ngx-bootstrap/locale';

import { isPlatformBrowser } from '@angular/common';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToolsService } from './core/services/tools.service';
import { TranslateBrowserLoader } from './core/translate-browser.service';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import Bugsnag from '@bugsnag/js';

// import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateServerService } from './core/translate-server.service';
import { IntlService } from './core/services/intl.service';



Bugsnag.start({
  apiKey: environment.bugsnagKey,
  enabledReleaseStages: ['prd'],
  releaseStage: environment.production ? 'prd' : 'local',
  onError: function (event) {

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(event?.originalError?.message)) {
      window.location.reload();
      return false;
    }

    if (event?.originalError?.functional) {
      // do not log on functional error
      return false;
    }
  }
});

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}

// used in date picker
defineLocale('pt', ptBrLocale);
defineLocale('es', esLocale);
defineLocale('en', enGbLocale);

// used in angular
registerLocaleData(localePtpt, 'pt');
registerLocaleData(localeEs, 'es');

export function createTranslateLoader(http: HttpClient, transferState: TransferState) {
  // return new TranslateHttpLoader(http, './assets/i18n-lang/', '.json');
  if (!environment.production) {
    return new TranslateBrowserLoader(transferState, http);
  } else {
    return new TranslateServerService(transferState);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    // TransferHttpCacheModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    RouterModule,
    BrowserAnimationsModule,
    // BrowserTransferStateModule,
    Angulartics2Module.forRoot(),
    // Angulartics2Module.forRoot([Angulartics2GoogleAnalytics]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient, TransferState],
      },
    }),
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (intlService) => {
        return intlService?.getCurrentLanguage();
      },
      deps: [IntlService],
    },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
