import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { pt, enUS, es, el } from 'date-fns/locale'
import { BehaviorSubject, Observable, map } from 'rxjs';
import { isPlatformServer, isPlatformBrowser, DOCUMENT } from '@angular/common';
import * as acceptLanguageParser from 'accept-language-parser';
import { Request } from 'express';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { CountryISO } from 'untold-ngx-intl-tel-input';
import { CountryService } from './country.service';
import { UserService } from './user.service';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';

@Injectable({
  providedIn: 'root'
})
export class IntlService {

  private currentCountry: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private translate: TranslateService, @Inject(PLATFORM_ID) protected platformId, @Optional() @Inject(REQUEST) private request: Request, @Inject(DOCUMENT) private document, private countryService: CountryService, private userService: UserService, private localeService: BsLocaleService) {
      this.setDefaultLanguageAndCountry();
  }

  setDefaultLanguageAndCountry() {
    const prerenderTag = $localize `@@country:pt`;

    const defaultPreRenderCountry = prerenderTag || 'pt';

    let language = null;
    let country = null;

    const availableLanguages = this.getAvailableLanguageCodes();
    this.translate.addLangs(availableLanguages);

    // 1 - domain default
    const currentHost = this.getHost();
    const currentHostDefaults = environment.domains.find(l => l.host === currentHost);

    language = currentHostDefaults?.language || defaultPreRenderCountry;
    country = currentHostDefaults?.defaultCountry || defaultPreRenderCountry;

    // 2 - subdomain/subpath override is handled by the SetLanguageGuard, only if the domain default country is en (buk.app, etc)

    // 3 - if local storage is saved in machine it overrides the domain default and subdomain
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage?.getItem('defaultLanguage')) {
        language = localStorage?.getItem('defaultLanguage');
      }
      // only override country from localstorage in en domain (buk.app, etc)
      if (country === 'us' && localStorage?.getItem('defaultCountry')) {
        country = localStorage?.getItem('defaultCountry');
      }
    }

    // 4- if user is logged in, we get the country from the merchant
    // this is done at the login/signup stage

    // Sync set language
    this.setCurrentLanguage(language, true);
    // set country
    this.setCurrentCountry(country);

    // Async set language
    // 5- if user is logged in, we get the language from the user
    const userSubscription = this.userService.currentUser.subscribe(user => {
      if (user?.language) {
        this.setCurrentLanguage(user.language);
      }
    });

  }

  getHost() {
    let host: any = '';
    if (isPlatformServer(this.platformId)) {
      const ssrHost = this.request?.headers?.['x-forwarded-host'];
      if (ssrHost) {
        host = ssrHost;
      }
      return host;
    } else {
      return this.document?.location?.host;
    }
  }

  getCurrentLanguage() {
    return this.translate.currentLang;
  }

  getClientLanguage(isClient = false) {
    if (isClient && isPlatformServer(this.platformId)) {
      // const request: Request = this.request;
      try {
        const languages = acceptLanguageParser.parse(this.request?.headers['accept-language']);
        if (languages && languages[0]) {
          return languages[0].code;
        } else {
          return 'en';
        }
      } catch (error) {
        return 'en';
      }
    } else {
      // if not a crawler, get language from browser locale
      if (!this.isBot()) {
        // get language from browser locale
        const browserLang = this.translate.getBrowserLang();
        const availableLanguages = this.getAvailableLanguageCodes();
        if (browserLang && availableLanguages.includes(browserLang)) {
          return browserLang;
        } else {
          return 'en';
        }
      }

      // get language from domain
      const language = environment.domains.find((v) => v.host === this.getHost());
      if (language) {
        return language.language;
      } else {
        return 'pt';
      }
    }
  }

  isBot() {
    const userAgent = navigator.userAgent.toLowerCase();
    const bots = ['googlebot', 'bingbot', 'slurp', 'duckduckbot', 'baiduspider', 'yandexbot', 'sogou', 'exabot', 'facebot', 'ia_archiver'];
    return bots.some(bot => userAgent.indexOf(bot) > -1);
  }


  getCurrentCountry() {
    return this.currentCountry.value;
  }

  languageChanged(): Observable<string> {
    return this.translate.onLangChange.pipe(map((event: LangChangeEvent) => event.lang));
  }

  countryChanged() {
    return this.currentCountry;
  }

  getAvailableLanguages() {
    return environment.languages;
  }

  getAvailableLanguageCodes() {
    return environment.languages.map(l => l.code);
  }

  setCurrentLanguage(lang, setDefault = false) {
    if (setDefault) {
      // this language will be used as a fallback when a translation isn't found in the current language
      this.translate.setDefaultLang(lang);
      this.translate.use(lang);
    } else {
      this.translate.use(lang);
    }

    this.localeService.use(lang);

    if (isPlatformBrowser(this.platformId)) {
      localStorage?.setItem('defaultLanguage', lang);
    }
  }

  setCurrentCountry(code: string) {
    this.currentCountry.next(code);
    if (isPlatformBrowser(this.platformId)) {
      localStorage?.setItem('defaultCountry', code);
    }
  }

  setCurrentCountryByName(name: string) {
    const countryCode = this.countryService.getCountryCode(name);
    const availableCountries = ['pt', 'es'];
    if (availableCountries.includes(countryCode)) {
      this.setCurrentCountry(countryCode);
    } else {
      this.setCurrentCountry('us');
    }
  }

  getDateFnsLocale() {
    let locale;
    switch (this.getCurrentLanguage()) {
      case 'pt':
        locale = pt;
        break;
      case 'es':
        locale = es;
        break;
      default:
        locale = enUS;
        break;
    }
    return locale;
  }

  getPreferedPhoneCountries() {
    const startCountry = this.getCurrentCountry();
    if (startCountry === 'pt') {
      return [CountryISO.Portugal, CountryISO.Brazil, CountryISO.UnitedStates]
    } else if (startCountry === 'es') {
      return [CountryISO.Spain, CountryISO.Mexico, CountryISO.UnitedStates]
    } else {
      return [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    }
  }

  getTimeFormat() {
    const language = this.getCurrentLanguage();
    return language !== 'en' ? 'HH:mm' : 'h:mm a';
  }

  getTimeFormatShort() {
    const language = this.getCurrentLanguage();
    return language !== 'en' ? 'H:mm' : 'h:mm a';
  }

  getDateTimeFormatShort(includeYear = false) {
    const language = this.getCurrentLanguage();
    return language !== 'en' ? `dd/MM${includeYear ? '/YYYY' : ''} H:mm` : `MM-dd${includeYear ? '/YYYY' : ''} h:mm a`;
  }

  getTimeFormatHour() {
    const language = this.getCurrentLanguage();
    return language !== 'en' ? 'HH' : 'h a';
  }

  getSupportEmail() {
    const language = this.getCurrentLanguage();
    if (language) {
      return environment.languages.find(v => v.code === language).supportEmail;
    }
  }

  getContactPhone() {
    const language = this.getCurrentLanguage();
    const languages = environment.languages;
    const currentLanguage = languages.find(l => l.code === language);
    return currentLanguage.contactPhone;
  }

  getContactWhatsapp() {
    const language = this.getCurrentLanguage();
    const languages = environment.languages;
    const currentLanguage = languages.find(l => l.code === language);
    // replace + for 00 and spaces with nothing
    const linkNumber = currentLanguage.contactWhatsapp.replace('+', '00').replace(/\+|\s/g, '');
    if (currentLanguage.contactWhatsapp) {
      return { text: currentLanguage.contactWhatsapp, link: `https://wa.me/${linkNumber}`, linkNumber};
    }
    return null;
  }
}
