import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { barbersLandingConfig } from '../../landing/configs/1A-barbersConfig';
import { generalLandingConfig } from '../../landing/configs/0-generalConfig';
import { salonLandingConfig } from '../../landing/configs/1B-salonConfig';
import { nailsLandingConfig } from '../../landing/configs/1C-nailsConfig';
import { fitnessLandingConfig } from '../../landing/configs/2A-fitnessConfig';
import { makeupLandingConfig } from '../../landing/configs/1D-makeupConfig';
import { reikiLandingConfig } from '../../landing/configs/4C-reikiConfig';
import { nutritionistLandingConfig } from '../../landing/configs/3C-nutritionistConfig';
import { physiotherapistsLandingConfig } from '../../landing/configs/3A-physiotherapistsConfig';
import { osteopathsLandingConfig } from '../../landing/configs/4A-osteopathsConfig';
import { dentistLandingConfig } from '../../landing/configs/3D-dentistConfig';
import { therapyLandingConfig } from '../../landing/configs/3F-therapyConfig';
import { pharmaciesLandingConfig } from '../../landing/configs/3E-pharmaciesConfig';
import { psychologistsLandingConfig } from '../../landing/configs/3B-psychologistsConfig';
import { gymsLandingConfig } from '../../landing/configs/2B-gymsConfig';
import { homeopathyLandingConfig } from '../../landing/configs/4B-homeopathyConfig';
import { acupunctureLandingConfig } from '../../landing/configs/4D-acupunctureConfig';


export interface LandingFeature {
  icon: string;
  title: string;
  text: string;
}

export interface LandingTestemony {
  text: string;
  image: string;
  person: string;
  buisiness: string;
}

export interface LandingBusiness {
  class: string;
  text: string;
  urls: BusinessURLs;
}

export interface BusinessFooter {
  title: string;
  links: { urls: BusinessURLs; text: string }[];
}

export interface BusinessURLs {
  pt: string;
  en: string;
  es: string;
}
export interface ILandingConfig {
  pageTitle?: string;
  pageDescription?: string;
  pageOgImage?: {en: string, pt: string, es: string};
  buisiness?: string;
  tagline?: string;
  heroImg?: string;
  appImg?: string;
  login?: string;
  registerCta?: string;
  contactCta?: string;
  headerDescription?: string;
  featuresTitle?: string;
  promoCode?: string;
  features?: LandingFeature[];
  testimoniesTitle?: string;
  testemonies?: LandingTestemony[];
  businessesTitle?: string;
  businesses?: LandingBusiness[];
  businessesFooter?: BusinessFooter[];
}

export enum LandingConfigs {
  GENERAL = 'general',
  BARBERS = 'barbers',
  SALON = 'salon',
  NAILS = 'nails',
  MAKEUP = 'makeup',
  FITNESS = 'fitness',
  REIKI = 'reiki',
  NUTRITIONIST = 'nutritionist',
  PHYSIOTHERAPISTS = 'physiotherapists',
  OSTEOPATHS = 'osteopaths',
  DENTIST = 'dentist',
  PSYCHOLOGISTS = 'psychologists',
  PHARMACIES = 'pharmacies',
  THERAPY = 'therapy',
  GYMS = 'gyms',
  HOMEOPATHY = 'homeopathy',
  ACUPUNCTURE = 'acupuncture',
}
@Injectable({
  providedIn: 'root',
})
export class LandingService {
  constructor(private translate: TranslateService) {}

  getLandingConfig(type: LandingConfigs, translations?: any) {
    let toReturn: ILandingConfig;
    switch (type) {
      case LandingConfigs.GENERAL:
        toReturn = generalLandingConfig;
        break;
      case LandingConfigs.BARBERS:
        toReturn = barbersLandingConfig;
        break;
      case LandingConfigs.SALON:
        toReturn = salonLandingConfig;
        break;
      case LandingConfigs.NAILS:
        toReturn = nailsLandingConfig;
        break;
      case LandingConfigs.MAKEUP:
        toReturn = makeupLandingConfig;
        break;
      case LandingConfigs.FITNESS:
        toReturn = fitnessLandingConfig;
        break;
      case LandingConfigs.REIKI:
        toReturn = reikiLandingConfig;
        break;
      case LandingConfigs.NUTRITIONIST:
        toReturn = nutritionistLandingConfig;
        break;
      case LandingConfigs.PHYSIOTHERAPISTS:
        toReturn = physiotherapistsLandingConfig;
        break;
      case LandingConfigs.OSTEOPATHS:
        toReturn = osteopathsLandingConfig;
        break;
      case LandingConfigs.DENTIST:
        toReturn = dentistLandingConfig;
        break;
      case LandingConfigs.PSYCHOLOGISTS:
        toReturn = psychologistsLandingConfig;
        break;
      case LandingConfigs.PHARMACIES:
        toReturn = pharmaciesLandingConfig;
        break;
      case LandingConfigs.THERAPY:
        toReturn = therapyLandingConfig;
        break;
      case LandingConfigs.GYMS:
        toReturn = gymsLandingConfig;
        break;
      case LandingConfigs.HOMEOPATHY:
        toReturn = homeopathyLandingConfig;
        break;
      case LandingConfigs.ACUPUNCTURE:
        toReturn = acupunctureLandingConfig;
        break;
    }

    return this.parseTranslations(JSON.parse(JSON.stringify(toReturn)), translations);
  }

  parseTranslations(data: ILandingConfig, translations: any): ILandingConfig {
    Object.keys(data).forEach((i) => {
      if (typeof data[i] === 'object') {
        data[i] = this.parseTranslations(data[i], translations);
      } else {
        if (data[i].includes('trans:')) {
          data[i] = this.translate.instant(data[i].replace('trans:', ''));
        }
      }
    });
    return data;
  }
}
