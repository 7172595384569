export const environment = {
  version: '1.0.1',
  production: true,
  apiUrl: 'https://api.buk.pt/',
  noInternetErrorMsg: 'Ops, looks like your are offline...',
  defaultLanguage: 'pt',
  languages: [
    { code: 'pt', name: 'Português', supportEmail: 'suporte@buk.pt', contactPhone: '+351 21 123 0425', contactWhatsapp: '+351 965 541 732' },
    { code: 'es', name: 'Español', supportEmail: 'apoyo@buk.es', contactPhone: '+34 988 80 91 64', contactWhatsapp: '+34 65 55 32 080' },
    { code: 'en', name: 'English', supportEmail: 'support@buk.app', contactPhone: '', contactWhatsapp: '' },
  ],
  domains: [
    { host: 'buk.app', language: 'en', defaultCountry: 'us', demoLink: 'https://calendly.com/bukdemo' },
    { host: 'buk.pt', language: 'pt', defaultCountry: 'pt', demoLink: 'https://api.whatsapp.com/send?phone=351965541732&text=Olá!%20Gostaria%20de%20ter%20mais%20informações.' },
    { host: 'buk.es', language: 'es', defaultCountry: 'es', demoLink: 'https://api.whatsapp.com/send?phone=34655532080&text=Hola!%20Me%20gustaria%20tener%20mais%20informaciones.' },
  ],
  maxSelfServiceNumberOfProviders: 20,
  facebookPixel: '563151531262339',
  facebookApp: {
    id: '374779849665123',
    version: 'v19.0',
  },
  googleApp: {
    clientId: '542739192323-3mdmh1hkhfkb3de9q9e4mlnlua6hrors.apps.googleusercontent.com',
  },
  stripe: {
    publishableKey: 'pk_live_VmDI0GeASeELebaehsvq5VLH',
  },
  firebase: {
    apiKey: 'AIzaSyDt9x1DDXc-o7HN0QsUBgUnZzGaY9TuDXw',
    authDomain: 'buk-pt.firebaseapp.com',
    databaseURL: 'https://buk-pt.firebaseio.com',
    projectId: 'buk-pt',
    storageBucket: 'buk-pt.appspot.com',
    messagingSenderId: '542739192323',
  },
  moloni: {
    id: 'bukfacturas',
    redirectUrl: 'https://buk.pt/moloni/',
    authUrl: 'https://api.moloni.pt/v1/authorize/',
  },
  posthogKey: 'phc_aPBKl3UCivvHPxHAVn9cc099xnaeEkx5Sb0CJiqL3rG',
  mixpanelKey: 'cdf244c355947b0a42da5fea7d1b694f',
  bugsnagKey: '1d6564970f42294234be640686d6c655',
  defaultColor: '#5055b7',
  cookieBot: {
    cbId: '636cd409-b3c5-4bc4-aa6e-e11549accdbb',
    blockingMode: 'auto'
  }
};
