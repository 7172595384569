import {MoloniOauthComponent} from './dashboard/moloni-oauth/moloni-oauth.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules, NoPreloading } from '@angular/router';
import { UserGuard } from './core/guards/user.guard';
import { MerchantGuard } from './core/guards/merchant.guard';
import { BukAdminGuard } from './core/guards/buk-admin.guard';
import { GlobalParamsGuard } from './core/guards/global-params.guard';
import { SetLanguageGuard } from './core/guards/set-language.guard';
import { environment } from '../environments/environment';
import { RoleAdminGuard } from './core/guards/role.admin.guard';
import { HasActiveSubscriptionGuard } from './core/guards/has-active-subscription.guard';

const languageRoutes: Routes = environment.languages.map(language => ({
  path: language.code,
  loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
  pathMatch: 'full',
  data: { state: 'subdomainLanguage' },
  canActivate: [SetLanguageGuard, GlobalParamsGuard],
}));

const routes: Routes = [
  {
    path: '',
    canActivate: [GlobalParamsGuard],
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    data: { state: 'landing' }
  },
  {
    path: 'ref/:referral',
    pathMatch: 'full',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    data: { state: 'referral' }
  },
  {
    path: 'pay/mbway/:id',
    pathMatch: 'full',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    data: { state: 'login' }
  },
  {
    path: 'signup',
    pathMatch: 'full',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    data: { state: 'signup' }
  },
  {
    path: 'signup/merchant',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    data: { state: 'setup_merchant' }
  },
  {
    path: 'recoverpass',
    pathMatch: 'full',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    data: { state: 'signup' }
  },
  {
    path: 'support',
    loadChildren: () => import('./support/support.module').then(m => m.SupportModule),
    data: { state: 'client' }
  },
  {
    path: 'logout',
    pathMatch: 'full',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'terms',
    pathMatch: 'full',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'privacy',
    pathMatch: 'full',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [BukAdminGuard],
  },
  {
    path: 'cancel-subscription',
    loadChildren: () => import('./cancelation/cancelation.module').then(m => m.CancelationModule),
    canActivate: [UserGuard, MerchantGuard, RoleAdminGuard, HasActiveSubscriptionGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [UserGuard, MerchantGuard],
    data: { state: 'dashboard' }
  },
  {
    path: ':slug/editor',
    loadChildren: () => import('./client-editor/client-editor.module').then(m => m.ClientEditorModule),
    canActivate: [UserGuard, MerchantGuard, RoleAdminGuard],
    data: { state: 'client' }
  },
  {
    path: ':slug/dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [UserGuard, MerchantGuard],
    data: { state: 'dashboard' }
  },
  {
    path: 'setup',
    loadChildren: () => import('./onboard/onboard.module').then(m => m.OnboardModule),
    canActivate: [UserGuard],
    data: { state: 'onboard' }
  },
  {
    path: 'moloni/auth',
    canActivate: [UserGuard, MerchantGuard],
    pathMatch: 'full',
    component: MoloniOauthComponent,
  },
  {
    path: 'qr/:uuid',
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
    canActivate: []
  },
  ...languageRoutes,
  {
    path: ':slug',
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule),
    canActivate: [SetLanguageGuard]
  },
  {
    path: ':slug/**',
    canActivate: [SetLanguageGuard],
    loadChildren: () => import('./client/client.module').then(m => m.ClientModule)
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    // preloadingStrategy: NoPreloading,
    enableTracing: false,
    paramsInheritanceStrategy: 'always',
    initialNavigation: 'enabledBlocking',
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
