import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiGatewayService } from './services/api-gateway.service';
import { AuthService } from './services/auth.service';
import { UserGuard } from './guards/user.guard';
import { BukAdminGuard } from './guards/buk-admin.guard';
import { RoleAdminGuard } from './guards/role.admin.guard';
import { RoleAdminOrManagerGuard } from './guards/role.admin.manager.guard';
import { GuestGuard } from './guards/guest.guard';
import { PaymentsService } from './services/payments.service';
import { UserService } from './services/user.service';
import { DevGuard } from './guards/dev.guard';
import { MerchantsService } from './services/merchants.service';
import { MarketingTemplateService } from './services/marketing-template.service';
import { StoresService } from './services/stores.service';
import { ToolsService } from './services/tools.service';
import { ClientService } from './services/client.service';
import { MerchantGuard } from './guards/merchant.guard';
import { SeoService } from './services/seo.service';
import { AnalyticsService } from './services/analytics.service';
import { AdminService } from './services/admin.service';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TransferState } from '@angular/platform-browser';
import { TranslateBrowserLoader } from './translate-browser.service';
import { LandingService } from './services/landing.service';
import { PlansService } from './services/plans.service';
import { UserWithoutMerchantGuard } from './guards/user-without-merchant.guard';
import { IntlService } from './services/intl.service';

export function createTranslateLoader(http: HttpClient, transferState: TransferState) {
  // return new TranslateHttpLoader(http, './assets/i18n-lang/', '.json');
  return new TranslateBrowserLoader(transferState, http);
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, TransferState]
      }
    })
  ],
  declarations: [],
  providers: [
    ApiGatewayService,
    AuthService,
    BukAdminGuard,
    UserGuard,
    UserWithoutMerchantGuard,
    RoleAdminGuard,
    RoleAdminOrManagerGuard,
    GuestGuard,
    HttpClient,
    PaymentsService,
    UserService,
    DevGuard,
    MerchantsService,
    MarketingTemplateService,
    StoresService,
    ToolsService,
    ClientService,
    MerchantGuard,
    SeoService,
    PlansService,
    AnalyticsService,
    AdminService,
    LandingService,
    IntlService
  ]
})
export class CoreModule { }
