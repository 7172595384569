import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { ToolsService } from '../services/tools.service';

@Injectable({
  providedIn: 'root'
})
export class BukAdminGuard  {
  constructor(@Inject(PLATFORM_ID) protected platformId, private router: Router, private toolsService: ToolsService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let user = null;

    if (isPlatformBrowser(this.platformId) && this.toolsService.localStorageSupport()) {
      user = localStorage?.getItem('currentUser');
    }

    if (user) {
      const parsedUser = JSON.parse(user);
      if ([
        80, // Goncalves
        68, // Moniz
        69, // Santos
        70, // Gois
        1480, // Catarina
        6975, // catarina catarina.monteiro+reunioes@buk.pt
        10575, // Maria Loureiro
        21717, // Zemi
        21725, // Zemi demos
      ].includes(parsedUser.id)) {
        return true;
      }
    }
    this.router.navigate(['/']);
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canActivate(next, state);
  }
}
