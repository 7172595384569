import { ILandingConfig } from '../../core/services/landing.service';
import { beautyLandingConfig } from './1-beautyConfig';

const configOverride: ILandingConfig = {
  pageTitle: 'trans:LANDING.landing.taglineNails',
  tagline: 'trans:LANDING.landing.taglineNails',
  heroImg: 'hero-nails.png',
  appImg: 'app-nails.png',
  buisiness: 'trans:LANDING.landing.businesses.nailss',
  featuresTitle: 'trans:LANDING.landing.featuresTitleNails',
};

export const nailsLandingConfig: ILandingConfig = {
  ...beautyLandingConfig,
  ...configOverride,
  testemonies: [
    {
      text: 'Aderir ao BUK foi das melhores decisões que tomei a nível profissional, poupo horas de trabalho por semana e as minhas clientes adoram o facto de serem autónomas nas marcações.',
      image: 'assets/av-ligia.jpg',
      person: 'Lígia Caseirito',
      buisiness: 'Nail Me Perfect',
    },
    {
      text: 'O BUK incentiva que o cliente marque no conforto da sua casa o que reduz o tempo que outrora usávamos para marcar. Os clientes preferem esta opção que para além de intuitiva é rápida. Estamos muito satisfeitos',
      image: 'assets/av-lash.jpg',
      person: 'Viviana',
      buisiness: 'The Lash House Professional',
    },
    {
      text: 'Desde que começámos a ter marcações online o nosso dia-a-dia ficou muito mais simples. Com o BUK consigo gerir várias clínicas e equipas, de forma simples e organizada e os clientes adoram!',
      image: 'assets/av-dlux.jpg',
      person: 'Sara Barros',
      buisiness: 'Clínica Dlux',
    },
  ],
};
