import { Injectable } from '@angular/core';

@Injectable()
export class PlansService {

  getPlanPrice(plan, showPriceWithoutVat, quantity) {
    let flatPrice = 0;
    let price = 0;

    if (showPriceWithoutVat) {
      flatPrice = plan?.flatPriceWithoutVat;
      price = plan?.priceWithoutVat;
    } else {
      flatPrice = plan?.flatPrice;
      price = plan?.price;
    }

    return (flatPrice + price * quantity) / 100;
  }

  getPlansBasedOnSettings(planId: 'pro' | 'pro_plus', withSMS, availablePlans) {
    let selectedPlans;
    if (withSMS) {
      if (planId === 'pro') {
        selectedPlans = availablePlans.plans.filter((p) => p.bukId === 'per_provider');
      } else {
        selectedPlans = availablePlans.plans.filter((p) => p.bukId === `per_provider_${planId}`);
      }
    } else {
      selectedPlans = availablePlans.plans.filter((p) => p.bukId === `per_provider_${planId}_without_sms`);
    }

    const planAnnually = selectedPlans.find((p) => p.id.endsWith('annual'));
    const planMonthly = selectedPlans.find((p) => !p.id.endsWith('annual'));

    return { monthly: planMonthly, annual: planAnnually }
  }
}
