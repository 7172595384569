import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class RoleAdminOrManagerGuard  {

  constructor(@Inject(PLATFORM_ID) protected platformId, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (isPlatformBrowser(this.platformId) && localStorage?.getItem('currentUser')) {
        const user = localStorage?.getItem('currentUser');
        if (user && JSON.parse(user) && (JSON.parse(user).role === 'manager' || JSON.parse(user).role === 'admin')) {
          return true;
        }
      }
      return false;
   }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canActivate(next, state);
  }
}
