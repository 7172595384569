import { ILandingConfig } from '../../core/services/landing.service';
import { beautyLandingConfig } from './1-beautyConfig';

const configOverride: ILandingConfig = {
  pageTitle: 'trans:LANDING.landing.taglineSalon',
  tagline: 'trans:LANDING.landing.taglineSalon',
  heroImg: 'hero-salon.png',
  appImg: 'app-salon.png',
  buisiness: 'trans:LANDING.landing.businesses.salons',
  featuresTitle: 'trans:LANDING.landing.featuresTitleSalon',
};

export const salonLandingConfig: ILandingConfig = {
  ...beautyLandingConfig,
  ...configOverride,
  testemonies: [
    {
      text: 'O BUK é fácil de usar, super interativo, prático e funcional, permite trabalhar de uma forma mais organizada e profissional.',
      image: 'assets/av-anton.jpg',
      person: 'Anton Beill',
      buisiness: 'Anton Beill Haircare',
    },
    {
      text: 'O Buk veio ajudar bastante a simplificar a minha rotina. Acabei com agendas de papel e passei para o digital, muito mais fácil, prático e acessível. Os meus clientes podem marcar online e isso ajuda-me imenso na gestão do meu tempo.',
      image: 'assets/av-suzy.jpg',
      person: 'Suzy Fernandes',
      buisiness: 'Suzy Fernandes Team',
    },
    {
      text: 'Confesso que fui bastante resistente nesta decisão, achei que fosse complicar mais a minha agenda e o meu dia dia, mas a partir do momento que experimentei senti que foi das melhores escolhas e investimento que já fiz no meu negócio!',
      image: 'assets/av-bks.jpg',
      person: 'BKS Image Lab',
      buisiness: '',
    },
  ],
};
