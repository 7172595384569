import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ToolsService } from './tools.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import { IntlService } from './intl.service';

@Injectable()
export class SeoService {

  constructor(private metaService: Meta,
    private titleService: Title,
    @Inject(DOCUMENT) private document,
    private translate: TranslateService,
    private toolsService: ToolsService,
    private intlService: IntlService) { }

  setMetaTags(title?, description?, image?, url?) {
    const laguage = this.intlService.getCurrentLanguage();
    if (!image) {
      if (laguage === 'pt') {
        image = 'https://' + this.intlService.getHost() + '/assets/ogimage.png';
      } else if (laguage === 'es') {
        image = 'https://' + this.intlService.getHost() + '/assets/ogimage_es.png';
      } else {
        image = 'https://' + this.intlService.getHost() + '/assets/ogimage_en.png';
      }
    }
    if (!url) {
      url = 'https://' + this.intlService.getHost();
    }

    if (!title) {
      this.translate.get(['CORE.seo.title',
        'CORE.seo.description',
      ]).subscribe((trans) => {
        this.updateTags(trans['CORE.seo.title'],
          trans['CORE.seo.description'], image, url);
      });
    } else {
      this.updateTags(title, description, image, url);
    }
  }

  updateTags(title, description, image, url) {
    this.document.documentElement.lang = this.intlService.getCurrentLanguage();

    if (image && image.indexOf('%') === -1) {
      image = encodeURI(image);
    }
    this.titleService.setTitle(title);
    this.metaService.updateTag({ property: 'og:site_name', content: title });
    this.metaService.updateTag({ property: 'og:title', content: title });
    this.metaService.updateTag({ property: 'og:description', content: description });
    this.metaService.updateTag({ name: 'description', content: description });
    this.metaService.updateTag({ property: 'og:image', content: image });
    // this.metaService.updateTag({ property: 'og:url', content: url });

  }

  setMobileIcons(image?) {
    if (image) {
      image = encodeURI(image);
    }
    let defaultImage = 'https://' + this.intlService.getHost() + '/assets/buk_ogg.jpg';
    if (!environment.production) {
      defaultImage = defaultImage.replace('https://', 'http://');
    }
    const connector = image && image.includes('?') ? '&' : '?';
    this.document.
      querySelector('[rel="apple-touch-icon"][sizes="57x57"]').setAttribute('href', image ? image + connector + 'c=57' : defaultImage);
    this.document.
      querySelector('[rel="apple-touch-icon"][sizes="60x60"]').setAttribute('href', image ? image + connector + 'c=60' : defaultImage);
    this.document.
      querySelector('[rel="apple-touch-icon"][sizes="72x72"]').setAttribute('href', image ? image + connector + 'c=72' : defaultImage);
    this.document.
      querySelector('[rel="apple-touch-icon"][sizes="76x76"]').setAttribute('href', image ? image + connector + 'c=76' : defaultImage);
    this.document.
      querySelector('[rel="apple-touch-icon"][sizes="114x114"]').setAttribute('href', image ? image + connector + 'c=114' : defaultImage);
    this.document.
      querySelector('[rel="apple-touch-icon"][sizes="120x120"]').setAttribute('href', image ? image + connector + 'c=120' : defaultImage);
    this.document.
      querySelector('[rel="apple-touch-icon"][sizes="144x144"]').setAttribute('href', image ? image + connector + 'c=144' : defaultImage);
    this.document.
      querySelector('[rel="apple-touch-icon"][sizes="152x152"]').setAttribute('href', image ? image + connector + 'c=152' : defaultImage);
    this.document.
      querySelector('[rel="apple-touch-icon"][sizes="180x180"]').setAttribute('href', image ? image + connector + 'c=180' : defaultImage);
    this.document.
      querySelector('[rel="icon"][sizes="192x192"]').setAttribute('href', image ? image + connector + 'c=192' : defaultImage);
    this.document.
      querySelector('[rel="icon"][sizes="32x32"]').setAttribute('href', image ? image + connector + 'c=32' : defaultImage);
    this.document.
      querySelector('[rel="icon"][sizes="96x96"]').setAttribute('href', image ? image + connector + 'c=96' : defaultImage);
    this.document.
      querySelector('[rel="icon"][sizes="16x16"]').setAttribute('href', image ? image + connector + 'c=16' : defaultImage);
    this.metaService.updateTag({ name: 'msapplication-TileImage', content: image ? image + connector + 'c=192' : defaultImage });
  }

}
