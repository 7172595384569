import { Component, OnInit, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { routerTransition } from './core/animations/router.animations';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { ToolsService } from './core/services/tools.service';
import { SeoService } from './core/services/seo.service';
import { Subscription } from 'rxjs';
import { setTheme } from 'ngx-bootstrap/utils';
import { AnalyticsService } from './core/services/analytics.service';
import { IntlService } from './core/services/intl.service';

// import * as fastclick from 'fastclick';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
})
export class AppComponent implements OnInit {
  onLangChange: Subscription = undefined;

  constructor(
    @Inject(PLATFORM_ID) protected platformId,
    @Inject(DOCUMENT) private document,
    private authService: AuthService,
    private router: Router,
    private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
    private translateService: TranslateService,
    private toolsService: ToolsService,
    private seoService: SeoService,
    private el: ElementRef,
    private analyticsService: AnalyticsService,
    private intlService: IntlService
  ) {
    setTheme('bs4'); // or 'bs5'
    // angulartics2GoogleAnalytics.startTracking();
    if (isPlatformBrowser(this.platformId)) {
      angulartics2GoogleTagManager.startTracking();
    }

    let token = null;
    if (isPlatformBrowser(this.platformId) && this.toolsService.localStorageSupport()) {
      localStorage?.removeItem('firebase:previous_websocket_failure'); // workaround for firebase bug
      const currentUser = JSON.parse(localStorage?.getItem('currentUser'));
      token = currentUser && currentUser.token;
    }

    if (token) {
      this.authService.getCurrentUserDetails().subscribe();
    }
  }

  ngOnInit() {
    // if (isPlatformBrowser(this.platformId)) {
    //   fastclick.attach(document.body);
    // }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      if (isPlatformBrowser(this.platformId)) {
        this.analyticsService.trackProductAnalyticsPageView(evt.urlAfterRedirects);
        window.scrollTo(0, 0);
        this.setChatVisibility(evt);
      }
    });

    this.onLangChange = this.translateService.onLangChange.subscribe(() => {
      if (isPlatformBrowser(this.platformId)) {
        this.updateLanguageHtml();
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.setAcquisitionDataToSessionStorage();
    }
  }

  setChatVisibility(evt) {
    if (
      [
        '/',
        '/faqs',
        '/privacy',
        '/terms',
        '/barbeiros',
        '/cabeleireiros',
        '/nail-artists',
        '/makeup-artists',
        '/personal-trainers',
        '/reiki',
        '/nutricionistas',
        '/fisioterapeutas',
        '/osteopatas',
        '/dentistas',
        '/psicologos',
        '/farmacias',
        '/centros-de-terapias',
        '/ginasios',
        '/homeopatia',
        '/acupuntura',
        '/spa-massagens',
      ].includes(evt.url)
      &&
      ['es', 'pt'].includes(this.intlService.getCurrentCountry())
    ) {
      this.document.body.classList.add('show-chat');
    } else {
      this.document.body.classList.remove('show-chat');
    }
  }

  setAcquisitionDataToSessionStorage() {
    this.toolsService.loadRouting();

    const urlSearchParams = new URLSearchParams(window.location.search);

    const signupReferredByUrl = document?.referrer;
    const signupLandingPage = window?.location?.pathname;

    let data = {
      signupUtmSource: urlSearchParams.get('utm_source'),
      signupUtmId: urlSearchParams.get('utm_id'),
      signupUtmMedium: urlSearchParams.get('utm_medium'),
      signupUtmCampaign: urlSearchParams.get('utm_campaign'),
      signupUtmTerm: urlSearchParams.get('utm_term'),
      signupUtmContent: urlSearchParams.get('utm_content'),
      signupReferredByUrl,
      signupLandingPage,
    };

    // avoid override properties on refresh
    try {
      const acquisitionDataRaw = sessionStorage.getItem('acquisition:data');
      const acquisitionData = acquisitionDataRaw ? JSON.parse(acquisitionDataRaw) : {};
      Object.keys(acquisitionData).forEach((key) => acquisitionData[key] == null && delete acquisitionData[key]);
      data = {...data, ...acquisitionData};
      sessionStorage.setItem('acquisition:data', JSON.stringify(data));
    } catch (error) {

    }
  }

  /**
   * Update the language in the lang attribute of the html element.
   */
  updateLanguageHtml(): void {
    const lang = document.createAttribute('lang');
    lang.value = this.translateService.currentLang;
    this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(lang);
  }

  getState(outlet) {
    return outlet.activatedRouteData.state;
  }
}
