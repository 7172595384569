import { ILandingConfig } from '../../core/services/landing.service';
import { altTherapyLandingConfig } from './4-altTherapyConfig';

const configOverride: ILandingConfig = {
  pageTitle: 'trans:LANDING.landing.taglineHomeo',
  tagline: 'trans:LANDING.landing.taglineHomeo',
  heroImg: 'hero-geral.png',
  appImg: 'app-geral.png',
  buisiness: 'trans:LANDING.landing.businesses.homeopathy',
  featuresTitle: 'trans:LANDING.landing.featuresTitleHomeo',
};

export const homeopathyLandingConfig: ILandingConfig = {
  ...altTherapyLandingConfig,
  ...configOverride,
  testemonies: [
    {
      text: 'O BUK possibilita uma gestão mais fácil dos horários das consultas, quer da disponibilidade do optometrista, quer na gestão da agenda do próprio cliente.',
      image: 'assets/av-alain.jpg',
      person: 'Maria Luísa Pereira Mota',
      buisiness: 'Alain Afflelou',
    },
    {
      text: 'O BUK foi a ferramenta que me ajudou a gerir melhor o meu ano 2021 e otimizar muito o meu trabalho. Super intuitivo e fácil de utilizar. Para além disso, não tenho que me preocupar com lembretes de consultas, poupa-me imenso tempo, uma vez que o BUK faz tudo isso por mim. As métricas lançadas por esta agenda on-line também são muito úteis pois consigo fazer um balanço de todo o meu trabalho.',
      image: 'assets/av-mariana.jpg',
      person: 'Mariana Moreira',
      buisiness: 'Nutricionista',
    },
    // {
    //   text: 'O BUK foi uma ferramenta fundamental para gestão de marcações, numa fase de maior afluência. É uma ferramenta muito intuitiva que permite uma comunicação automatizada com o cliente, o que foi extremamente facilitador no dia a dia.',
    //   image: 'assets/av-farma.jpg',
    //   person: 'Farmácia Lamaçães',
    //   buisiness: '',
    // },
  ],
};
