
import {throwError as observableThrowError,  Observable, catchError, map, throwError } from 'rxjs';
import { UserService } from './user.service';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ToolsService } from './tools.service';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { IntlService } from './intl.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ApiGatewayService {
  public token;

  constructor(@Inject(PLATFORM_ID) protected platformId: Object,
    private http: HttpClient, private router: Router, private userService: UserService, private toolsService: ToolsService, private intlService: IntlService, private translateService: TranslateService) {
    this.updateToken();
  }

  updateToken() {
    if (isPlatformBrowser(this.platformId) && this.toolsService.localStorageSupport()) {
      const currentUser = JSON.parse(localStorage?.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
    } else {
      this.token = null;
    }
  }

  get(url: string, params?: HttpParams): Observable<any> {

    url = environment.apiUrl + url;
    this.updateToken();
    const options = {
      headers: this.getHeaders(),
      params: new HttpParams()
    };

    if (params) {
      options.params = params;
    }

    return this.http
      .get(url, options).pipe(
      map(val => val && val['data']),
      catchError(err => this.handleError(err))
      );
  }

  getWithPagination(url: string, params?: HttpParams): Observable<any> {
    url = environment.apiUrl + url;
    this.updateToken();
    const options = {
      headers: this.getHeaders(),
      params: new HttpParams()
    };

    if (params) {
      options.params = params;
    }

    return this.http
      .get(url, options).pipe(
      catchError(err => this.handleError(err))
      );
  }

  post(url: string, payload: any = {}): Observable<any> {
    url = environment.apiUrl + url;
    this.updateToken();
    const options = {
      headers: this.getHeaders(),
    };

    return this.http
      .post(url, payload, options).pipe(
      map(val => val && val['data']),
      catchError(err => this.handleError(err))
      );
  }

  postFacebookConversionApi(pixel: string, token: string, payload: any = {}): Observable<any>  {
    const url = `https://graph.facebook.com/v14.0/${pixel}/events?access_token=${token}`;
    return this.http
      .post(url, payload).pipe(
      catchError(err => this.handleError(err))
      );
  }

  put(url: string, payload: any = {}): Observable<any> {
    url = environment.apiUrl + url;
    this.updateToken();
    const options = {
      headers: this.getHeaders(),
    };

    return this.http
      .put(url, payload, options).pipe(
      map(val => val && val['data']),
      catchError(err => this.handleError(err))
      );
  }

  delete(url: string, payload: any = {}): Observable<any> {
    url = environment.apiUrl + url;
    this.updateToken();
    const options = {
      headers: this.getHeaders(),
      body: payload
    };

    return this.http
      .delete(url, options).pipe(
      map(val => val && val['data']),
      catchError(err => this.handleError(err))
      );
  }

  getHeaders() {
    const requestLanguage = this.intlService.getCurrentLanguage();
    if (this.token) {
      return new HttpHeaders().set('Accept-Language', requestLanguage).set('Authorization', 'Bearer ' + this.token);
    }
    return new HttpHeaders().set('Accept-Language', requestLanguage);
  }

  private handleError(error: HttpErrorResponse | any) {
    let errMsg;
    let errData;
    errMsg = this.translateService.instant('GENERAL.generalErrorMsg');
    let functional = false;

    if (error instanceof HttpErrorResponse) {
      // console.log('GOT ERROR API GATEWAY', error.status, error.message, error.error);
      if (error.error.data) {
        errData = error.error.data;
      }
      if (error.status === 0) {
        errMsg = environment.noInternetErrorMsg;
        functional = true;
      } else if (error.status === 401) {
        this.handleUnauthorized();
        functional = true;
      } else if (error.status === 500) {
        errMsg = this.translateService.instant('GENERAL.generalErrorMsg');
      } else if (error.error) {
        const errors = [];
        const response = error.error;
        Object.values(response).map(elem => {
          if (typeof elem === 'string') {
            errors.push(elem);
          }
          if (elem.constructor === Array) {
            const array = <Array<any>>elem;
            array.map(e => {
              if (typeof e === 'string') {
                errors.push(e);
              }
            });
          }
        });
        errMsg = errors.join('\n');
      }
    }

    const err = new Error(errMsg);
    err['errData'] = errData;
    err['errMsg'] = errMsg;
    err['errHttp'] = error?.error;
    err['functional'] = functional;

    return throwError(() => err);
  }

  handleUnauthorized() {
    // TODO clear current user, somehow
    this.token = null;
    this.userService.currentUser.next(null);
    if (isPlatformBrowser(this.platformId)) {
      localStorage?.removeItem('currentUser');
      // remove cookie for skiping prerender
      document.cookie = '__session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }
    this.router.navigate(['/login']);

    if (mixpanel?.__loaded) {
      mixpanel.reset();
    }
    if (posthog) {
      posthog.reset(true);
    }
  }

  serializeParams(obj) {
    const str = [];
    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }
    return str.join('&');
  }
}
