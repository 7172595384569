import { map } from 'rxjs';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiGatewayService } from './api-gateway.service';

import { isPlatformBrowser } from '@angular/common';
import { AnalyticsService } from './analytics.service';
import { ToolsService } from './tools.service';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import { IntlService } from './intl.service';

@Injectable()
export class AuthService {

  public token: string;

  constructor(@Inject(PLATFORM_ID) protected platformId,
    private api: ApiGatewayService, private router: Router, private userService: UserService, private analytics: AnalyticsService, private toolsService: ToolsService, private intlService: IntlService) {
    if (isPlatformBrowser(this.platformId) && this.toolsService.localStorageSupport()) {
      const currentUser = JSON.parse(localStorage?.getItem('currentUser'));
      this.token = currentUser && currentUser.token;
    }
  }

  getToken() {
    return this.token;
  }

  login(email: string, password: string): Observable<boolean> {
    return this.api.post('auth/signin', { email: email, password: password }).pipe(
      map((response) => this.saveLoginInfo(response)));
  }

  saveLoginInfo(response) {
    const token = response && response['token'];
    if (token) {
      this.token = token;
      localStorage?.setItem('currentUser', JSON.stringify({
        email: response['user'].email,
        role: response['user'].role,
        token: token,
        hasMerchant: response['user'].hasMerchant,
        merchantSlug: response['user'].merchantSlug,
        socialEmailEmpty: response['user'].socialEmailEmpty,
        firstName: response['user'].firstName,
      }));
      this.getCurrentUserDetails().subscribe();
      return true;
    } else {
      return false;
    }
  }

  signup(payload): Observable<boolean> {
    return this.api.post('auth/signup', payload).pipe(
      map((response) => {
        const token = response && response['token'];
        if (token) {
          this.token = token;
          localStorage?.setItem('currentUser', JSON.stringify(
            { email: payload.email, token: token, hasMerchant: false, merchantSlug: false }
          ));
          this.getCurrentUserDetails().subscribe();
          return true;
        } else {
          return false;
        }
      }));
  }

  facebookAuth(fbToken) {
    return this.api.get('auth/facebook?access_token=' + fbToken).pipe(
      map((response) => {
        const token = response && response['token'];
        if (token) {
          this.token = token;
          localStorage?.setItem('currentUser', JSON.stringify({
            token: token,
            hasMerchant: response['user'].hasMerchant,
            merchantSlug: response['user'].merchantSlug,
            socialEmailEmpty: response['user'].socialEmailEmpty,
            firstName: response['user'].firstName,
          }));
          if (response['user'].hasMerchant) {
            this.analytics.trackEvent('login', 'engagement', 'facebook');
            this.analytics.trackProductAnalyticsEvent('login', {medium: 'facebook'});
            localStorage?.removeItem('referral');
          } else {
            this.analytics.trackEvent('sign_up', 'engagement', 'facebook');
            this.analytics.trackProductAnalyticsEvent('sign_up', {
              medium: 'facebook',
            });
            this.analytics.trackTikTokPixel('CompleteRegistration', 'facebook');
          }
          this.getCurrentUserDetails().subscribe();
          return true;
        } else {
          return false;
        }
      }));
  }

  googleAuth(googleToken, method) {
    let call;
    if (method === 'google-one-tap') {
      call = this.api.post('auth/google-one-tap', {credential: googleToken});
    } else {
      call = this.api.get('auth/google?access_token=' + googleToken);
    }
    return call.pipe(
      map((response: any) => {
        const token = response && response['token'];
        if (token) {
          this.token = token;
          localStorage?.setItem('currentUser', JSON.stringify({
            token: token,
            hasMerchant: response['user'].hasMerchant,
            merchantSlug: response['user'].merchantSlug,
            socialEmailEmpty: response['user'].socialEmailEmpty,
            firstName: response['user'].firstName,
          }));
          if (response['user'].hasMerchant) {
            this.analytics.trackEvent('login', 'engagement', method);
            this.analytics.trackProductAnalyticsEvent('login', {medium: method});
            localStorage?.removeItem('referral');
          } else {
            this.analytics.trackEvent('sign_up', 'engagement', method);
            this.analytics.trackProductAnalyticsEvent('sign_up', {
              medium: method,
            });
            this.analytics.trackTikTokPixel('CompleteRegistration', method);
          }
          this.getCurrentUserDetails().subscribe();
          return true;
        } else {
          return false;
        }
      }));
  }

  getCurrentUserDetails() {
    return this.api.get('users/me').pipe(map(val => {
      const currentUserDetails = JSON.parse(localStorage?.getItem('currentUser'));
      const newDetails = val;
      const updatedUser = Object.assign(currentUserDetails || {} , newDetails);
      this.userService.currentUser.next(updatedUser);
      localStorage?.setItem('currentUser', JSON.stringify(updatedUser));
      // set cookie for skiping prerender
      if (isPlatformBrowser(this.platformId)) {
        document.cookie = '__session=true;path=/';
      }
      if (!updatedUser.language) {
        this.updateSelf({...val, language: this.intlService.getCurrentLanguage()}).subscribe();
      }
      return val;
    }));
  }

  sendResetPasswordEmail(payload) {
    return this.api.post('auth/forgot', payload);
  }

  resetPassword(payload) {
    return this.api.post('auth/reset/password', payload);
  }

  magicLink(token) {
    return this.api.post('auth/magic/link', {token});
  }

  logout(): void {
    this.token = null;
    setTimeout(() => this.userService.currentUser.next(null));
    localStorage?.removeItem('currentUser');
    // remove cookie for skiping prerender
    document.cookie = '__session=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    if (mixpanel?.__loaded) {
      mixpanel.reset();
    }
    if (posthog) {
      posthog.reset(true);
    }

    if (isPlatformBrowser(this.platformId)) {
      window.location.href = '/';
    } else {
      this.router.navigateByUrl('/');
    }
  }

  updateUserEmail(email) {
    return this.api.put('users/me', {email}).pipe(
      map((response) => this.saveLoginInfo(response)));
  }

  updateSelf(payload: {
    email: string, firstName: string, lastName?: string, language: string, password?: string, newPassword?: string, notificationNewBooking: boolean, notificationCanceledBooking: boolean, pushNotificationNewBooking: boolean, pushNotificationCanceledBooking: boolean
  }): Observable<boolean> {
    return this.api.put('users/me', payload).pipe(
      map((response) => this.saveLoginInfo(response)));
  }

}
