import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class UserGuard  {

  constructor(@Inject(PLATFORM_ID) protected platformId, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if (isPlatformBrowser(this.platformId) && localStorage?.getItem('currentUser')) {
        return true;
      }

      if (isPlatformBrowser(this.platformId)) {
        localStorage?.setItem('redirect:url', state.url);
      }
      this.router.navigate(['/login']);
      return false;
   }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canActivate(next, state);
  }
}
