import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { MerchantsService } from '../services/merchants.service';

@Injectable()
export class MerchantGuard  {
  constructor(@Inject(PLATFORM_ID) protected platformId, private router: Router, private merchantService: MerchantsService ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let user = null;

    if (isPlatformBrowser(this.platformId)) {
      user = localStorage?.getItem('currentUser');
    }

    if (
      user &&
      JSON.parse(user) &&
      JSON.parse(user).hasMerchant
    ) {
        return true;
    }

    if (user && JSON.parse(user) && !JSON.parse(user).hasMerchant) {
      this.router.navigate(['/signup/merchant']);
    } else {
      this.router.navigate(['/login']);
    }
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.canActivate(next, state);
  }

}
