import { Injectable } from '@angular/core';
import { ApiGatewayService } from './api-gateway.service';

@Injectable()
export class PaymentsService {

  constructor(private api: ApiGatewayService) { }

  activatePlan(merchantId, payload) {
    return this.api.put(`merchants/${merchantId}/plan`, payload);
  }

  getUpcomingInvoice(merchantId, payload) {
    return this.api.put(`merchants/${merchantId}/upcominginvoice`, payload);
  }

  activateMBWaySubscription(merchantId, payload) {
    return this.api.post(`merchants/${merchantId}/mbway/plan`, payload);
  }

  getPlans(country = 'pt') {
    return this.api.get(`plans?country=${country}`);
  }

  applyVoucher(merchantId, coupon) {
    return this.api.post(`merchants/${merchantId}/coupon`, { coupon });
  }

  getPaymentMethods(merchantId) {
    return this.api.get(`merchants/${merchantId}/paymentmethod`);
  }

  updatePaymentMethod(merchantId, payload) {
    return this.api.put(`merchants/${merchantId}/paymentmethod`, payload);
  }

  getStripeCustomerPaymentIntent(storeId, serviceIds) {
    return this.api.post(`stores/${storeId}/stripe/customer/paymentintent`, {services: serviceIds});
  }

  sendMbWayRenewIntent(merchantSlug, phoneNumber) {
    return this.api.post(`payments/subscription/mbway/${merchantSlug}`, {phoneNumber});
  }

  getPendingSubscriptionRenewInfo(merchantSlug) {
    return this.api.get(`payments/subscription/info/${merchantSlug}`);
  }

  cancelSubscription(merchantId, payload) {
    return this.api.post(`merchants/${merchantId}/subscription/cancel`, payload);
  }

}
