import {StoresService} from './../../core/services/stores.service';
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-moloni-oauth',
  templateUrl: './moloni-oauth.component.html',
  styleUrls: ['./moloni-oauth.component.scss']
})
export class MoloniOauthComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private storesService: StoresService, private router: Router) { }

  ngOnInit() {
    const code = this.activatedRoute.snapshot.queryParams.code;
    const storeSlug = this.activatedRoute.snapshot.queryParams.store;
    this.storesService.connectMoloni(storeSlug, code).subscribe(store => {
      this.router.navigateByUrl('/' + store.slug + '/dashboard/settings');
    }, error => {});
  }

}
