import { environment as devEnv } from './src/environments/environment';
import { environment as stagingEnv } from './src/environments/environment.staging';
import { environment as prodEnv } from './src/environments/environment.prod';

// export function to get language from url for prerendering and cookie banner
export function getLanguageFromUrl(domain: string, url: string): { languageCode; url } {
  const availableLanguages = devEnv.languages.map((lang) => lang.code);

  const ptDomains = [...devEnv.domains, ...stagingEnv.domains, ...prodEnv.domains].filter((d) => d.language === 'pt').map((d) => d.host);
  const esDomains = [...devEnv.domains, ...stagingEnv.domains, ...prodEnv.domains].filter((d) => d.language === 'es').map((d) => d.host);
  const enDomains = [...devEnv.domains, ...stagingEnv.domains, ...prodEnv.domains].filter((d) => d.language === 'en').map((d) => d.host);

  let languageCode;

  if (ptDomains.includes(domain)) {
    languageCode = 'pt';
  } else if (enDomains.includes(domain)) {
    languageCode = 'en';
  } else if (esDomains.includes(domain)) {
    languageCode = 'es';
  }

  // when domain contains a language in the first subfolder we override the languageCode
  const requestPathSplit = url.split('/')?.[1];
  if (requestPathSplit && availableLanguages.includes(requestPathSplit)) {
    languageCode = requestPathSplit;
    availableLanguages.forEach((lang) => {
      url = url.replace(`/${lang}/`, '/');
    });
  }

  return { languageCode, url };
}
