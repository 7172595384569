import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiGatewayService } from './api-gateway.service';

@Injectable()
export class ClientService {

  public daySearchResultCache: BehaviorSubject<{providers: string, services: string, storeId: string, days: [], lastLoadedDate: string}> = new BehaviorSubject({providers: null, services: null, storeId: null, days: null, lastLoadedDate: null});

  constructor(private api: ApiGatewayService) { }

  getMerchant(slug, payload = {}) {
    return this.api.get(`merchants/slug/${slug}?${this.api.serializeParams(payload)}`);
  }

  getMerchantByUuid(uuid, payload = {}) {
    return this.api.get(`merchants/uuid/${uuid}?${this.api.serializeParams(payload)}`);
  }
  getMerchantByReferral(referral, payload = {}) {
    return this.api.get(`merchants/referral/${referral}?${this.api.serializeParams(payload)}`);
  }

  getStoreServices(storeId, providerId?, onlyWithProviders = false) {
    return this.api.get(`stores/${storeId}/services?onlyWithProviders=${onlyWithProviders}${providerId ? '&providerId=' + providerId : ''}`);
  }

  getStoreProviders(storeId, services = null, lite = false) {
    if (services) {
      return this.api.get(`stores/${storeId}/providers?services=${services.map(s => s.id).join(',')}&lite=${lite}`);
    } else {
      return this.api.get(`stores/${storeId}/providers?lite=${lite}`);
    }
  }

  getStoreAvailability(payload) {
    return this.api.post(`stores/${payload.storeId}/availability`, payload);
  }

  createBooking(storeId, payload) {
    return this.api.post(`stores/${storeId}/bookings`, payload);
  }

  cancelBookingPayment(id, code) {
    return this.api.delete(`bookings/${id}/paymentfailed/${code}`);
  }

  getBooking(bookingId, bookingCode) {
    return this.api.get(`bookings/${bookingId}/${bookingCode}`);
  }

  cancelBooking(bookingId, bookingCode) {
    return this.api.delete(`bookings/${bookingId}/${bookingCode}`);
  }

  sendFacebookConversionApiEvent(pixel, token, payload) {
    return this.api.postFacebookConversionApi(pixel, token, payload);
  }

  getVisitorLocation() {
    return this.api.get('utils/location');
  }
}
